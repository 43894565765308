<template>
  <v-container>
    <v-alert
      v-model="isSuccessSend"
      border="left"
      close-text="Закрыть"
      color="green lighten-4"
      dismissible
    >
      Приглашение отправлено успешно!
    </v-alert>
    <v-card>
      <v-card-title>Пригласить друга</v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          Вы можете пригласить Ваших друзей или знакомых стать агентами ООО
          «МСБ-Лизинг». Для этого укажите почтовый адрес Вашего друга в поле
          ниже и нажмите на кнопку. Вашему другу будет отправлено на почту
          письмо, содержащее ссылку на регистрацию.
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="Введите почтовый адрес друга"
            required
            :error-messages="emailErrors"
            type="email"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" @click="sendEmail"
            >Отправить приглашение на регистрацию</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Referral",
  data: () => ({
    valid: true,
    email: "",
    emailRules: [
      v => !!v || "Введите E-mail",
      v => /.+@.+/.test(v) || "Неправильно заполнено поле E-mail"
    ],
    isSuccessSend: false,
    emailErrors: []
  }),
  methods: {
    sendEmail: function() {
      this.isSuccessSend = false;
      this.emailErrors = [];
      if (this.$refs.form.validate()) {
        let code = null;
        if (this.$store.getters.isLoggedIn) {
          code = this.$store.state.user.referralCode;
        }
        let that = this;
        this.$http({
          url: "/agents/user-profile/send-referral-email",
          data: {
            email: this.email,
            code: code
          },
          method: "POST"
        })
          .then(() => {
            that.isSuccessSend = true;
            that.email = "";
          })
          .catch(err => {
            that.emailErrors.push(err.message);
          });
      }
    }
  },
  mounted() {
    const breadcrumbs = [
      {
        text: "Главная",
        disabled: false,
        href: "/"
      },
      {
        text: "Пригласить друга",
        disabled: true
      }
    ];
    this.$store.commit("setBreadCrumbs", breadcrumbs);
  }
};
</script>

<style scoped></style>
