<template>
  <v-bottom-sheet v-model="isShowBottomSheet" persistent>
    <v-sheet class="text-center" height="200px">
      <v-btn class="mt-6" text color="red" @click="close">
        закрыть
      </v-btn>
      <div class="py-3">
        {{ bottomSheetMessage }}
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: "BottomSheet",
  computed: {
    isShowBottomSheet: function() {
      return this.$store.getters.isShowBottomSheet;
    },
    bottomSheetMessage: function() {
      return this.$store.state.bottomSheetMessage;
    }
  },
  methods: {
    close: function() {
      this.$store.commit("closeBottomSheetMessage");
    }
  }
};
</script>

<style scoped></style>
