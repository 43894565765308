var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('h2',[_vm._v("Обратная связь")]),_c('v-breadcrumbs',{staticClass:"breadcrumbs",attrs:{"items":[
      {
        text: 'Главная',
        disabled: false,
        href: '/'
      },
      {
        text: 'Обратная связь',
        disabled: true
      }
    ]}}),(!_vm.formSubmit)?_c('v-form',{ref:"form",staticClass:"mt-5",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.sendForm.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"mb-4"},[_vm._v(" Оставьте обратную связь, мы обязательно учтем ваши комментарии и пожелания. "),_c('br'),_vm._v("Если вопрос требует дополнительных уточнений - мы с вами свяжемся. ")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":_vm.errors.name,"rules":_vm.nameRules,"label":"Имя","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","rules":_vm.phoneRules,"error-messages":_vm.errors.phone,"counter":"","label":"Телефон","maxlength":"10","prefix":"+7","required":"","type":"tel"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('v-textarea',{attrs:{"outlined":"","dense":"","error-messages":_vm.errors.text,"label":"Комментарий","name":"msg","rows":"3"},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}),_c('v-btn',{staticClass:"mr-4 mt-1",attrs:{"disabled":!_vm.valid,"color":"primary","type":"submit"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"16"}},[_vm._v("fas fa-paper-plane")]),_vm._v(" Отправить ")],1)],1):[_c('h3',[_vm._v("Спасибо!")]),_vm._v(" Ваши комментарии помогают нам сделать сервис лучше! ")]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }