<template>
  <v-footer class="text-center transparent white--text">
    <v-col cols="12"> &copy; ООО «МСБ-Лизинг» {{ year }}</v-col>
  </v-footer>
</template>
<script>
export default {
  props: {
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style lang="scss">
footer {
  position: relative;
  margin: 0;
  padding: 40px 0 56px;
  background: #2f3338;
  color: #999;
  font-size: 12px;
  line-height: 16px;

  a {
    color: #1ca263 !important;
  }
}
</style>
