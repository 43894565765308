<template>
  <div v-resize="get_btn_group_wrap_height">
    <!--    buttons_group_num= {{ buttons_group_num }};-->
    <!--    currentPrev= {{ currentPrev }};-->
    <!--    currentNext= {{ currentNext }}-->
    <!--    btn_group_wrap_height=  {{ btn_group_wrap_height}};-->
    <!--    btn_group_offest={{btn_group_offest}};-->
    <!--    browser_width={{browser_width}}-->
    <!--    btn_group_wrap_height = {{btn_group_wrap_height}}-->
    <div class="pda_wrap mx-4 d-block d-md-none">
      <div class="pda_breadcrumbs">Агенский кабинет — О сервисе</div>
      <h1 class="pda_welcome__title">О сервисе</h1>
      <div
        v-for="(slide, i) in slider"
        :key="i"
        class="pda_slide"
        :class="{ hide_conent: i !== slider_model }"
        :id="'pda_slide' + i"
      >
        <div
          @click="pdaClick(i)"
          :key="i"
          :id="'pda_title' + i"
          class="pda_title"
        >
          {{ slide.title }}
        </div>
        <div class="pda_slide_content">
          <div v-html="slide.content"></div>

          <a
            class="pda_slide_btn"
            :href="slide.slug ? slide.slug : '/create-leasing'"
            >{{ slide.button ? slide.button : "передать заявку" }}
          </a>
        </div>
      </div>
      <div class="footer pda_footer pt-5 pb-5">
        © ООО «МСБ-Лизинг» {{ new Date().getFullYear() }}
      </div>
    </div>
    <v-row
      no-gutters
      class="height100 d-none d-md-flex"
      style="border-top: 1px solid #EEEEEE;"
    >
      <v-col class="col col1">
        <div class="row no-gutters height100 my_justify_between">
          <div class="my_spacer"></div>
          <div class="col_center col-auto d-flex flex-column justify-end">
            <div class="flex-grow-1">
              <h1 class="welcome__title title_font">О сервисе</h1>
              <div class="d-flex flex-column">
                <v-row
                  :class="{ 'd-none': currentPrev < 1 }"
                  class="more_link_row more_link_row2"
                  @click="showPrevClick(currentPrev)"
                >
                  <v-col class="more_link_col more_link_col1 flex-grow-0"
                    >Вернуться назад
                  </v-col>
                  <v-col class="more_link_col" style="padding: 0;">
                    <div class="more_link_col_middle"></div>
                  </v-col>
                  <v-col
                    class="more_link_col more_link_col_ico flex-grow-0"
                    cols="1"
                    ><img
                      src="/landing/more_ico.png"
                      style="transform: scaleY(-1)"
                  /></v-col>
                </v-row>
                <div ref="btn_group_wrap" class="btn_group_wrap">
                  <div
                    class="btn_group"
                    :style="
                      'transform: translateY(-' + btn_group_offest + 'px)'
                    "
                  >
                    <router-link
                      v-for="(btn, i) in slider"
                      :to="{ name: 'About', params: { step: i } }"
                      :key="i"
                      class="about_btn"
                      :class="{
                        about_btn_bg: i < 7,
                        about_btn_basic: i > 6,
                        about_btn__active: i == step
                      }"
                      :style="
                        ' background-image: url(/about_pics/about' +
                          (i + 1) +
                          '.jpg)'
                      "
                    >
                      <!--                      :style="'&#45;&#45;bgurl:url(/about_pics/' + btn.bg + ')'"-->
                      <span> {{ btn.title }}</span>
                    </router-link>
                  </div>
                </div>

                <v-row
                  v-if="slider[currentNext]"
                  class="more_link_row more_link_row1"
                  :class="{ more_link_row1__group1: buttons_group_num == 1 }"
                  @click="showNextClick(currentNext)"
                >
                  <v-col class="more_link_col more_link_col1 flex-grow-0"
                    >Еще вопросы о сервисе
                  </v-col>
                  <v-col class="more_link_col" style="padding: 0;">
                    <div class="more_link_col_middle"></div>
                  </v-col>
                  <v-col
                    class="more_link_col more_link_col_ico flex-grow-0"
                    cols="1"
                    ><img src="/landing/more_ico.png" alt=""
                  /></v-col>
                </v-row>
              </div>
            </div>
            <div class="footer mb-3 py-lg-5 mb-lg-4">
              © ООО «МСБ-Лизинг» {{ new Date().getFullYear() }}
              <span class="ml-4 mr-4">|</span>
              <a
                style=" color: rgba(0, 0, 0, 0.87);"
                href="https://t.me/agent_msb"
                target="_blank"
              >
                <img
                  class="mr-1"
                  src="/landing/ico_telegram.png"
                  style="vertical-align: middle;height: 20px;"
                />
                телеграм-канал для агентов</a
              >
            </div>
          </div>
        </div>
      </v-col>
      <v-col class="col col2">
        <v-carousel
          :show-arrows="false"
          hide-delimiter-background
          light
          height="100%"
          class="slider_carousel"
          v-model="slider_model"
        >
          <v-carousel-item
            v-for="(slide, i) in slider"
            :key="i"
            class="slide"
            :style="
              slide.bg ? 'background-image:url(/about_pics/' + slide.bg : ''
            "
          >
            <div class="slide_flex">
              <div class="slide_wrap_about">
                <div class="slide_title title_font">
                  {{ slide.title }}
                </div>
                <div
                  class="slide_txt"
                  :class="{ slide_txt_title_long: slide.title.length > 30 }"
                  v-html="slide.content"
                ></div>
                <div>
                  <a
                    class="slide_btn"
                    :href="slide.slug ? slide.slug : '/create-leasing'"
                    >{{ slide.button ? slide.button : "передать заявку" }}
                  </a>
                </div>
              </div>
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "About",
  props: ["step"],
  data: function() {
    return {
      btn_group_wrap_height: 530,
      slider: []
    };
  },
  // меняем html title через компонент vue-meta
  metaInfo() {
    return {
      title: "Агентский кабинет «МСБ-Лизинг». О сервисе. " + this.title
    };
  },
  methods: {
    showNextClick(currentNext) {
      this.routerGo(currentNext);
    },
    showPrevClick(currentPrev) {
      this.routerGo(currentPrev);
    },

    routerGo(i) {
      this.$router.push({ name: "About", params: { step: i } });
    },
    pdaClick(i) {
      // клик по  текущему разделу - скрываем/показываем ответ, на другой - переходим
      if (i !== this.slider_model) {
        this.routerGo(i);
      } else {
        document
          .getElementById("pda_slide" + i)
          .classList.toggle("hide_conent");
      }
    },
    get_btn_group_wrap_height() {
      this.btn_group_wrap_height = this.$refs.btn_group_wrap.clientHeight;
    }
  },
  mounted() {
    this.get_btn_group_wrap_height();
  },
  created() {
    this.$store
      .dispatch("getStepPages")
      .then(() => {
        this.slider = this.$store.state.stepPages;

        // на мобильном прокручиваем экран (до ответа на открытый вопрос)
        this.$nextTick(() => {
          this.$el.querySelector("#pda_slide" + this.step).scrollIntoView();
        });
      })
      .catch(err => {
        console.log(err);
      });
  },

  computed: {
    slider_model: {
      get() {
        return parseInt(this.step);
      },
      set(i) {
        if (i !== this.slider_model) this.routerGo(i);
      }
    },
    title() {
      return this.slider[this.slider_model]
        ? this.slider[this.slider_model].title
        : "";
    },
    // номер текущей группы вопросов-кнопок
    buttons_group_num() {
      return this.step == 0
        ? 1
        : Math.ceil((this.slider_model + 1) / this.count);
    },
    count() {
      return 7; // кол-во вопросов в блоке
    },

    // номер вопроса (в пред блоке)  для кнопки назад
    currentPrev() {
      return (this.buttons_group_num - 1) * this.count - 1;
    },
    // номер вопроса  (в след блоке)  для кнопки вперед
    currentNext() {
      return this.currentPrev + this.count + 1;
    },
    // browser_width(){
    //   return this.$vuetify.breakpoint.width;
    // },

    btn_group_offest() {
      switch (this.buttons_group_num) {
        case 1:
          return 0;
        case 2:
          return this.btn_group_wrap_height - 2;
        case 3:
          return 2 * this.btn_group_wrap_height - 2;
      }
      return 0;
    }
  }
};
</script>
<style lang="scss" scoped>
.my_spacer {
  @media (min-width: 1550px) {
    flex-grow: 1;
  }
}

.my_justify_between {
  @media (max-width: 1550px) {
    justify-content: space-between;
  }
}

.col1 {
  @media (max-width: 1550px) {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
}

.col2 {
  @media (max-width: 1550px) {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
}
.btn_group_wrap {
  position: relative;
  height: 544px;
  overflow: hidden;

  @media (max-width: 1550px) {
    height: 454px;
  }

  @media (max-width: 1350px) {
    height: 398px;
  }
}

.btn_group {
  max-width: 460px;
  position: absolute; //4 css trasition
  transition: transform 0.33s ease-in;
}

.about_btn {
  display: block;
  position: relative;

  margin-bottom: 18px;
  width: 100%;
  margin-left: 1px; // 4 outline
  margin-right: 1px; // 4 outline
  min-height: 60px;
  line-height: 60px; // 4 center span
  padding-left: 10px;
  padding-right: 10px;

  border-radius: 4px;

  color: #ffffff;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  text-decoration: none;

  cursor: pointer;
  user-select: none;

  transition: all 0.5s;

  span {
    line-height: 15px;
    display: inline-block;
    vertical-align: middle;
  }

  @media (max-width: 1550px) {
    min-height: 50px !important;
    line-height: 50px !important; // 4 center span
    //padding-top: 13px;
    margin-bottom: 15px;
    //padding-bottom: 0;
  }
  @media (max-width: 1400px) {
    min-height: 46px !important;
    line-height: 46px !important; // 4 center span
    //padding-top: 10px;
    margin-bottom: 11px;
  }
}

.about_btn_bg {
  background-color: #cacaca;
  background-blend-mode: multiply;
  background-size: cover !important;
  background-position-y: -60px !important;
}

.about_btn_bg.about_btn__active {
  border-left: 5px solid $msb-green;
}

.about_btn_bg:hover {
  box-shadow: 12px 1px 20px rgba(0, 0, 0, 0.27);
}

.about_btn_basic {
  background-color: #fff;
  color: #333333;
  //border: 1px solid $msb-green2;
  outline: 1px solid $msb-green2;
}

.about_btn_basic:hover,
.about_btn_basic.about_btn__active {
  color: $msb-green2;
}

.about_btn__active::after {
  content: "▶";
  font-size: 1em;
  color: $msb-green2;
  opacity: 0.7;
  position: absolute;
  right: -1.5em;
  //top: 18px;
}

.welcome__title {
  user-select: none;
  margin-top: 40px;
  margin-bottom: 20px;

  color: #333333;
  @media (max-width: 1550px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media (max-width: 1300px) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  @media (max-width: 600px) {
    margin-top: 5px;
    margin-bottom: 20px;
  }
}

.pda_welcome__title {
  font-weight: 800;
  font-size: 32px;
  line-height: 55px;
  margin-bottom: 20px;
}

.title_font {
  font-size: 32px;
  font-weight: 800;

  @media (max-width: 1550px) {
    font-size: 24px;
    line-height: 130% !important;
  }
}

.slide_title {
  color: #ffffff;
  font-weight: 800;

  line-height: 45px;
  margin-bottom: 15px;
  padding-right: 3em;
  margin-top: 40px;

  @media (max-width: 1500px) {
    margin-bottom: 15px;
  }
  @media (max-width: 1400px) {
    margin-top: 10px;
  }
}

.slide_flex {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: calc(100vh - 183px);
  max-height: 770px;
}

.slide_wrap_about {
  display: flex;
  flex-direction: column;
  max-height: 100%;

  padding-left: 20px;
  padding-right: 20px;

  padding-top: 50px;
  padding-bottom: 80px;


  margin-left: 124px;
  @media (max-width: 1550px) {
    margin-top: 20px;
    margin-left: 40px;
  }

  @media (min-width: 1000px) {
    margin-top: 80px;
    margin-left: 100px;
  }

  @media (min-width: 1900px) {
    margin-top: 220px;
    margin-left: 210px;
  }

}

.slide_txt {
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #ffffff;

  //margin-top: 30px;
  margin-bottom: 60px;
  padding-right: 60px;
  overflow: auto;

  max-height: calc(558px);
  scrollbar-width: thin; // only FF
  scrollbar-color: rgba(180, 177, 177, 0.2) rgba(125, 122, 122, 0.2); // only FF

  @media (max-width: 1500px) {
    font-size: 14px;
    max-height: calc(70vh) !important;
    margin-bottom: 20px;
  }
  @media (max-width: 1400px) {
    font-size: 14px;
    max-height: calc(370px) !important;
    margin-bottom: 20px;
  }
}

// заголовок в 2 строки, подгоняем под левый заголовок ("О сервисе")
.slide_txt_title_long {
  max-height: calc(500px);
}

//https://xhtml.ru/2020/css/css-scrollbars/?
// for Chrome-based
.slide_txt::-webkit-scrollbar {
  width: 4px; /* ширина scrollbar */
}

.slide_txt::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1); /* цвет дорожки */
}

.slide_txt::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2); /* цвет плашки */
  border-radius: 4px; /* закругления плашки */
}

//https://stackoverflow.com/a/66851150   ::v-deep
.slide_txt::v-deep .step_br {
  display: block;
  content: "";
  height: 0.5em;
}

.pda_slide_content::v-deep a:link,
.pda_slide_content::v-deep a:visited {
  color: #1ca263;
}

.slide_txt::-webkit-scrollbar-thumb:hover {
  background-color: #a6a6a6;
}

.slide_btn {
  display: inline-block;
  min-width: 211px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 30px;
  padding-right: 30px;

  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 600;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.3s;
  background: rgba(255, 255, 255, 0.1);
}

.slide_btn:link,
.slide_btn:visited {
  color: white !important;
}

.slide_btn:hover {
  background: rgba(255, 255, 255, 0.25);
}

.slide {
  background-size: cover;
}

.slider_carousel {
  background-image: url(/about_pics/secondary.jpg);
  background-size: cover;
}

.footer {
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  @media (max-width: 1550px) {
    display: none;
  }
}

.pda_footer {
  text-align: left;
}

.col1_wrap {
}

.col_center {
  max-width: 720px;
  min-width: 500px;
  width: 90%;
}

.icon {
  height: 57px;
  @media (min-width: 1000px) {
    height: 64px;
  }
}

.info_text {
  font-size: 12px;
  line-height: 20px;
}

.subtitle {
  font-style: normal;
  font-weight: 300;
  color: #040404;
  font-size: 11px;
  line-height: 14px;

  @media (min-width: 1000px) {
    font-size: 18px;
    line-height: 20px;
  }
}

.links-wrapper {
  width: 100%;
  margin-top: 0px;
  @media (min-height: 800px) {
    margin-top: 50px;
  }
}

.height100 {
  @media (min-width: 1000px) {
    height: calc(100vh - 86px);
  }
}

.more_link_col {
  @media (max-width: 1550px) {
    padding: 5px 15px;
  }
}

.more_link_row1 {
  margin-top: 0px;
}
.more_link_row1__group1 {
  margin-top: 54px;
}

.more_link_row2 {
  margin-bottom: 3px;
}

.more_link_row {
  user-select: none;
  width: 457px;
  cursor: pointer;
  @media (max-width: 1550px) {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.more_link_col1 {
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #c4c4c4;

  margin-top: 9px;
}

.more_link_col_middle {
  border-bottom: 1px dashed #c4c4c4;
  display: block;
  margin-top: 35px;
}

.more_link_col_ico img {
  @media (max-width: 1300px) {
    width: 35px;
    margin-left: 6px;
  }
}
.pda_breadcrumbs {
  font-size: 10px;
  color: #9f9f9f;
}

.pda_wrap {
  padding-top: 30px;
  border-top: 1px solid #e9e9e9;
}

.pda_slide {
  margin-bottom: 15px;
}

.pda_title {
  display: block;
  line-height: 120%;
  font-weight: 600;
  font-size: 17px;
  user-select: none;
  cursor: pointer;
  transition: color 0.2s;
  margin-bottom: 12px;
  text-decoration: none;
}

.pda_slide_content {
  font-size: 15px;
}

.hide_conent .pda_slide_content {
  display: none;
}

.hide_conent .pda_title {
  color: rgba(51, 51, 51, 0.75);
}

.pda_title,
.pda_title:hover {
  color: #1ca263;
}

.pda_slide_btn:link {
  text-decoration: none;
}

.pda_slide_btn {
  display: block;
  color: rgba(51, 51, 51, 0.75);
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 17px;
  margin-top: 30px;
  margin-bottom: 40px;
}

.pda_slide_btn:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>
