<template>
  <div class="d-flex flex-column justify-space-between">
    <div>
      <v-row v-if="isLoggedIn">
        <v-col cols="2">
          <router-link to="/Profile">
            <img :src="require('@/assets/img/avatar2.png')" />
          </router-link>
        </v-col>
        <v-col>
          <div class="User ml-2">
            <router-link
              to="/Profile"
              style="text-decoration: none;color: initial"
              >{{ user.name }}
              <span v-if="user.is_pro" class="pro_level_label">Про</span>
            </router-link>
          </div>

        </v-col>
      </v-row>

      <div class="mb-2 support text--secondary" style="line-height: 115%;">
        <span style="text-transform: uppercase;font-size: 70%;">Служба заботы и качества</span><br>
        Табак Валерия <br>
        <a style="font-size: 80%;text-decoration: none;" href="tel:+79054873151">+7-905-487-31-51</a>

      </div>

      <v-btn
        block
        x-large
        color="primary"
        :to="user.is_pro ? '/create-leasing-pro' : '/create-leasing'"
        class="mb-6"
        >Новая заявка +</v-btn
      >
      <!--      <v-btn block x-large color="primary" to="/create-leasing-pro" class="mb-6"-->
      <!--      >Новая заявка ПРО +</v-btn-->
      <!--      >-->
      <v-btn block x-large color="grey" to="/calc" outlined>Калькулятор</v-btn>
      <div class="Calc_info mt-3">
        Предварительный расчет и предложение клиенту
      </div>

      <ul class="Menu mt-12 pt-5 mb-3">
        <template v-if="isLoggedIn">
          <li>
            <router-link to="/Dossier"
              >Мои заявки
              <span class="newComments" v-if="newComments">{{
                newComments
              }}</span></router-link
            >
          </li>
          <li>
            <router-link to="/Payments"
              >Мои выплаты
              <span class="newComments" v-if="newPayments">{{
                newPayments
              }}</span></router-link
            >
          </li>

          <li>
            <router-link to="/Profile">Профиль</router-link>
          </li>
          <li>
            <router-link to="/Relation">Агентский договор</router-link>&nbsp;
            <!--            договор подписан-->
            <span
              style="color:#0e69df"
              v-if="this.$store.state.user.contract_state === 2"
              >✔︎</span
            >
            <!--            договор - отклонен -->
            <span
              style="color:red"
              v-if="this.$store.state.user.contract_state === 3"
              >✗︎</span
            >
            <!--            договор - отклонен на дополнение -->
            <span
              style="color:red"
              v-if="this.$store.state.user.contract_state === 4"
              >✎</span
            >
          </li>
          <li>
            <router-link to="/Rates">Тарифы выплат</router-link>
          </li>

          <li class="call" style=" margin-top: 50px">
            <router-link
              to="/Call"
              title="Если у вас возникли вопросы
по заявкам или работе сервиса,
будем рады ответить на все вопросы"
              >Заказать звонок
            </router-link>
          </li>
        </template>

        <li class="call">
          <router-link
            to="Feedback"
            title="Оставьте обратную связь,
     мы обязательно учтем ваши комментарии и пожелания"
            >Обратная связь</router-link
          >
        </li>
        <li>
          <a
            style="color: #5BB04F;"
            href="https://t.me/agent_msb"
            target="_blank"
          >
            <img
              class=""
              src="/landing/ico_telegram.png"
              style="vertical-align: middle;height: 20px;"
            />
            канал для агентов</a
          >
        </li>
      </ul>
      <a
        href="https://www.moneyfriends.ru/lk-agent/?utm_source=MSB#/signUp"
        target="_blank"
        border="0"
      >
        <img
          class="mb-1 mt-4"
          :src="require('@/assets/img/mf_banner_desktop2.png')"
      /></a>
      <br />
      <br />
    </div>
    <div>
      <v-btn
        v-if="isLoggedIn"
        @click="$emit('logout')"
        color="grey"
        class="mt-2 mb-5"
        outlined
        >Выйти</v-btn
      >
    </div>
    <div>
      <router-link to="/agreement" style="font-size: 80%;text-decoration:none;"
        >Пользовательское соглашение</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "LeftMenu",
  props: {
    isLoggedIn: {
      type: Boolean
    },
    user: {
      type: Object
    },
    newComments: {
      type: Number
    },
    newPayments: {
      type: Number
    }
  },
  data: function() {
    return {
      callOrderModal: false
    };
  },
  methods: {}
};
</script>

<style lang="scss">
.newComments {
  padding: 1px 7px;
  display: inline-block;
  border-radius: 14px;
  background: #0e69df;
  color: white;
}
.User {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 90%;
  line-height: 110%;
  display: inline-block;
  vertical-align: bottom;
}

.Menu {
  border-top: 1px solid #dddddd;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 80%;
  list-style: none;
}

.Calc_info {
  font-size: 10px;
  line-height: 100%;
  text-align: center;
}

.Menu li {
  margin-top: 10px;
}

.Menu a:link,
.Menu a:visited {
  text-decoration: none;
  color: black;
  transition: all 0.2s;
}

.Menu li a:hover {
  color: $msb-green !important;
  border-bottom: 1px solid grey;
}

//.call {
//  margin-top: 50px !important;
//}

.call a:link,
.call a:visited {
  color: $msb-green !important;
}
.pro_level_label {
  border: 1px solid #dbe3ee;
  background-color: #f0f6ff;
  color: #0d5aa7;
  font-size: 80%;
  font-weight: initial;
  border-radius: 5px;
  display: inline-block;
  padding: 2px 5px;
  margin-left: 3px;
}
</style>
