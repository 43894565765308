<template>
  <v-container>
    <ChangeAgentType></ChangeAgentType>
    <PersonalDataForm
      v-if="isTypeChange"
      :agent-type="agentType"
    ></PersonalDataForm>
  </v-container>
</template>

<script>
import ChangeAgentType from "@/components/ChangeAgentType";
import PersonalDataForm from "@/components/PersonalDataForm";

export default {
  name: "PersonalData",
  components: {
    ChangeAgentType,
    PersonalDataForm
  },
  computed: {
    agentType: function() {
      return this.$store.state.user.type;
    },
    isTypeChange: function() {
      return !!this.agentType;
    }
  }
};
</script>

<style scoped></style>
