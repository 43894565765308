<template
  ><span>
    <v-btn color="white" class="mr-4 mt-6" @click="createPhoneCall"
      >Заказать звонок</v-btn
    >
    <v-dialog v-model="callOrderModal" max-width="290">
      <v-card>
        <v-card-title class="headline">
          Заказ звонка
        </v-card-title>
        <v-card-text class="black--text">
          С вами свяжутся в ближайшее время.
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
  name: "CallBackButton",
  data: function() {
    return {
      callOrderModal: false
    };
  },
  methods: {
    createPhoneCall() {
      const that = this;
      this.$store
        .dispatch("callOrder", {})
        .then(() => {
          that.callOrderModal = true;
        })
        .catch(err => {
          console.error(err.data);
        });
    }
  }
};
</script>
