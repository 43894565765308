<template>
  <v-container>
    <h2>Соглашение на обработку персональных данных</h2>
    <v-breadcrumbs
      :items="[
        {
          text: 'Главная',
          disabled: false,
          href: '/'
        },
        {
          text: 'Соглашение на обработку ПД',
          disabled: true
        }
      ]"
      class="breadcrumbs"
    ></v-breadcrumbs>

    <v-card>
      <v-tabs v-model="tab" background-color="primary" fixed-tabs dark>
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#jur">
          Юр лица (ООО)
        </v-tab>

        <v-tab href="#fiz">
          ИП и физ. лица
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="jur">
          <v-card flat>
            <v-card-text>
              <p>
                Информация, предоставленная мной/нами ООО
                &laquo;МСБ-Лизинг&raquo; в&nbsp;связи с&nbsp;установлением
                договорных отношений, является полной, точной и&nbsp;достоверной
                во&nbsp;всех отношениях.<br />
              </p>
              <p>
                Настоящим подтверждаем, что представленная информация
                соответствует действительности, не&nbsp;возражаем против
                проверки ее&nbsp;достоверности, в&nbsp;том числе
                с&nbsp;использованием услуг других операторов (третьих лиц),
                а&nbsp;также использовать информацию о&nbsp;неисполнении и/или
                ненадлежащем исполнении договорных обязательств, в&nbsp;случае
                их&nbsp;возникновения, в&nbsp;том числе при рассмотрении
                вопросов о&nbsp;предоставлении новых разработанных компанией
                продуктах и&nbsp;заключении новых договоров. <br />
              </p>
              <p>
                Мы&nbsp;подтверждаем, что получены все необходимые согласия лиц
                о&nbsp;предоставлении (передаче, сообщении, распространении,
                обработке и&nbsp;пр.) ООО &laquo;МСБ-Лизинг&raquo; (и&nbsp;иным
                третьим лицам, уполномоченными ООО &laquo;МСБ-Лизинг&raquo;
                и/или с&nbsp;которыми у&nbsp;ООО &laquo;МСБ-Лизинг&raquo;
                установлены гражданско-правовые отношения) информации
                о&nbsp;них, в&nbsp;том числе относящейся к&nbsp;персональным
                данным физических лиц.<br />
              </p>
              <p>
                В&nbsp;случае разработки ООО &laquo;МСБ-Лизинг&raquo; новых
                продуктов и&nbsp;услуг, настоящим выражаем свое согласие
                на&nbsp;информирование нас об&nbsp;этих продуктах
                и&nbsp;услугах, в&nbsp;том числе, но&nbsp;не&nbsp;ограничиваясь,
                с&nbsp;использованием почтовой, телефонной, факсимильной связи,
                электронной почты и&nbsp;пр. <br />
              </p>
              <p>
                Мы&nbsp;согласны с&nbsp;тем, что предоставленный в&nbsp;ООО
                &laquo;МСБ-Лизинг&raquo; пакет документов останется в&nbsp;ООО
                &laquo;МСБ-Лизинг&raquo;.<br />
              </p>
              <p>
                Мы&nbsp;извещены о&nbsp;том, что ООО &laquo;МСБ-Лизинг&raquo;
                имеет право отказать в&nbsp;заключении соответствующего догвора
                без объяснения причин отказа.<br />
              </p>
              <p>
                Не&nbsp;возражаем против предоставления переданной/сообщенной
                нами информации банкам (кредитным организациям), в&nbsp;которых
                у&nbsp;ООО &laquo;МСБ-Лизинг&raquo; открыт расчетный счет. Также
                настоящим признаем и&nbsp;подтверждаем, что настоящее согласие
                считается данным любым третьим лицам, уполномоченными ООО
                &laquo;МСБ-Лизинг&raquo; и/или с&nbsp;которыми у&nbsp;ООО
                &laquo;МСБ-Лизинг&raquo; установлены гражданско-правовые
                отношения, и&nbsp;такие третьи лица имеют право
                на&nbsp;обработку предоставленных данных на&nbsp;основании
                настоящего согласия при условии соблюдения ограничений
                относительно допустимых целей их&nbsp;обработки.<br />
              </p>
              <p>
                В&nbsp;соответствии с&nbsp;ФЗ &#8470;&nbsp;218
                от&nbsp;30.12.2004 &laquo;О&nbsp;кредитных историях&raquo;
                настоящим также даем ООО &laquo;МСБ-Лизинг&raquo; согласие
                на&nbsp;получение из&nbsp;любого бюро кредитных историй
                информации/кредитных отчетов об&nbsp;организации для целей
                принятия решений о&nbsp;целесообразности установления договорных
                отношений, проверки финансовой устойчивости клиента
                в&nbsp;период действия настоящего согласия; настоящее согласие
                считается действительным в&nbsp;течении&nbsp;6 (шести) месяцев
                со&nbsp;дня его оформления.<br />
              </p>
              <p>
                В&nbsp;случае изменения сведений, указанных и&nbsp;переданных
                нами ООО &laquo;МСБ-Лизинг&raquo; в&nbsp;течение действия
                договорных отношений, обязуемся в&nbsp;3-хдневный срок сообщить
                в&nbsp;ООО &laquo;МСБ-Лизинг&raquo; о&nbsp;произошедших
                изменениях.
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="fiz">
          <v-card flat>
            <v-card-text>
              <p>
                Информация, предоставленная мной ООО &laquo;МСБ-Лизинг&raquo;
                в&nbsp;связи с&nbsp;установлением договорных отношений, является
                полной, точной и&nbsp;достоверной во&nbsp;всех отношениях.<br />
              </p>
              <p>
                Настоящим подтверждаю, что представленная информация
                соответствует действительности, не&nbsp;возражаю против проверки
                ее&nbsp;достоверности, в&nbsp;том числе с&nbsp;использованием
                услуг других операторов (третьих лиц), а&nbsp;также использовать
                информацию о&nbsp;неисполнении и/или ненадлежащем исполнении
                договорных обязательств, в&nbsp;случае их&nbsp;возникновения,
                в&nbsp;том числе при рассмотрении вопросов о&nbsp;предоставлении
                новых разработанных компанией продуктах и&nbsp;заключении новых
                договоров. <br />
              </p>
              <p>
                В&nbsp;случае разработки ООО &laquo;МСБ-Лизинг&raquo; новых
                продуктов и&nbsp;услуг, настоящим выражаю свое согласие
                на&nbsp;информирование меня (или моего представителя)
                об&nbsp;этих продуктах и&nbsp;услугах, в&nbsp;том числе,
                но&nbsp;не&nbsp;ограничиваясь, с&nbsp;использованием почтовой,
                телефонной, факсимильной связи, электронной почты и&nbsp;пр.
                <br />
              </p>
              <p>
                Настоящим выражаю согласие с&nbsp;тем, что предоставленный
                в&nbsp;ООО &laquo;МСБ-Лизинг&raquo; пакет документов останется
                в&nbsp;ООО &laquo;МСБ-Лизинг&raquo;.<br />
              </p>
              <p>
                Настоящим подтверждаю, что извещен о&nbsp;том, что ООО
                &laquo;МСБ-Лизинг&raquo; имеет право отказать в&nbsp;заключении
                соответствующего догвора без объяснения причин отказа.<br />
              </p>
              <p>
                Не&nbsp;возражаю против предоставления переданной/сообщенной
                информации банкам (кредитным организациям), в&nbsp;которых
                у&nbsp;ООО &laquo;МСБ-Лизинг&raquo; открыт расчетный счет. Также
                настоящим признаю и&nbsp;подтверждаю, что настоящее согласие
                считается данным любым третьим лицам, уполномоченными ООО
                &laquo;МСБ-Лизинг&raquo; и/или с&nbsp;которыми у&nbsp;ООО
                &laquo;МСБ-Лизинг&raquo; установлены гражданско-правовые
                отношения, и&nbsp;такие третьи лица имеют право
                на&nbsp;обработку предоставленных данных на&nbsp;основании
                настоящего согласия при условии соблюдения ограничений
                относительно допустимых целей их&nbsp;обработки.<br />
              </p>
              <p>
                В&nbsp;соответствии с&nbsp;ФЗ &#8470;&nbsp;218
                от&nbsp;30.12.2004 &laquo;О&nbsp;кредитных историях&raquo;
                настоящим также даю ООО &laquo;МСБ-Лизинг&raquo; согласие
                на&nbsp;получение из&nbsp;любого бюро кредитных историй
                информации/кредитных отчетов обо мне для целей принятия решений
                о&nbsp;целесообразности установления договорных отношений,
                проверки финансовой устойчивости в&nbsp;период действия
                настоящего согласия; настоящее согласие считается действительным
                в&nbsp;течении&nbsp;6 (шести) месяцев со&nbsp;дня его
                оформления.<br />
              </p>
              <p>
                В&nbsp;случае изменения сведений, указанных и&nbsp;переданных
                мной ООО &laquo;МСБ-Лизинг&raquo; в&nbsp;течение действия
                договорных отношений, обязуюсь в&nbsp;3-хдневный срок сообщить
                в&nbsp;ООО &laquo;МСБ-Лизинг&raquo; о&nbsp;произошедших
                изменениях. <br />
              </p>
              <p>
                Настоящим даю свое согласие ООО &laquo;МСБ-Лизинг&raquo;
                (344010, г. Ростов-на-Дону, пр. Ворошиловский, 62, ИНН
                6164218952, ОГРН 1046164002983) на&nbsp;обработку моих
                персональных данных, включая их&nbsp;получение от&nbsp;меня
                и/или от&nbsp;любых третьих лиц, с&nbsp;учетом требований
                действующего законодательства&nbsp;РФ, и&nbsp;подтверждаю, что,
                давая такое согласие, я&nbsp;действую своей волей
                и&nbsp;в&nbsp;своем интересе.<br />
              </p>
              <p>
                Согласие дается мною на&nbsp;обработку ООО
                &laquo;МСБ-Лизинг&raquo; следующей информации: мои фамилия, имя,
                отчество, год, месяц, дата и&nbsp;место рождения, адрес, номер
                телефона, семейное, социальное, имущественное положение,
                образование, профессия, доходы, и&nbsp;любая иная информация,
                относящаяся к&nbsp;моей личности, доступная, либо известная
                в&nbsp;любой конкретный период времени ООО
                &laquo;МСБ-Лизинг&raquo; (далее&nbsp;&mdash; &laquo;Персональные
                данные&raquo;), предусмотренная Федеральным законом
                &#8470;&nbsp;152-ФЗ от&nbsp;27.07.2006г.
                &laquo;О&nbsp;персональных данных&raquo;. Согласие дано для
                целей исполнения договорных обязательств, в&nbsp;случае
                возникновения таковых.<br />
              </p>
              <p>
                Настоящее согласие дается на&nbsp;неопределенный срок
                и&nbsp;может быть мною отозвано, но&nbsp;не&nbsp;ранее даты
                прекращения договорных обязательств, в&nbsp;случае, если таковые
                возникнут между сторонами. Порядок отзыва согласия&nbsp;&mdash;
                по&nbsp;личному заявлению. При этом ООО &laquo;МСБ-Лизинг&raquo;
                имеет право хранить мои персональные данные в&nbsp;течение срока
                хранения документов, установленного действующим
                законодательством&nbsp;РФ, а&nbsp;в&nbsp;случаях,
                предусмотренных законодательством&nbsp;РФ, передавать
                уполномоченным органам государственной власти.<br />
              </p>
              <p>
                Настоящее согласие дается мною ООО &laquo;МСБ-Лизинг&raquo;
                на&nbsp;осуществление любых действий в&nbsp;отношении моих
                персональных данных, в&nbsp;том числе: сбор, запись,
                систематизацию, накопление, хранение, уточнение (обновление,
                изменение), извлечение, использование, передачу (предоставление,
                доступ), распространение, обезличивание, блокирование, удаление,
                уничтожение, трансграничную передачу Персональных данных,
                а&nbsp;также осуществление любых иных действий с&nbsp;моими
                Персональными данными с&nbsp;учетом действующего
                законодательства. Настоящим выражаю свое согласие
                на&nbsp;проверку ООО &laquo;МСБ-Лизинг&raquo; достоверности
                представленных мною персональных данных, в&nbsp;том числе
                с&nbsp;использованием услуг других операторов.
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Agreement_personal",
  data: () => ({
    tab: 0
  }),

  methods: {}
};
</script>

<style lang="scss">
.howto {
  border: 1px solid #cacaca;
  padding: 15px;
  font-size: 90%;
  margin-bottom: 20px;
  border-right: 8px solid $msb-green;
  border-radius: 10px;
  line-height: 130%;
}
.howto h4 {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}
h4 {
  color: $msb-green;
  margin-top: 20px;
  margin-bottom: 8px;
}
</style>
