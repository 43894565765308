<template>
  <div id="nav" :class="{ nav_wrapper_cabinet: !this.$route.meta.landing }">
    <v-app-bar
      dense
      height="auto"
      color="white"
      class="nav-bar-wrapper v-app-bar--hide-shadow pa-3"
    >
      <a :href="$route.path == '/' ? 'https://msb-leasing.ru' : '/' " style="">
        <img class="logo my-3" v-bind:src="img" />
      </a>

      <v-toolbar dense flat>
        <div class="flex-grow-1"></div>
        <v-toolbar-items class="hidden-sm-and-down">
          <v-btn
            plain
            :ripple="false"
            class="nav-link nav-btn"
            href="https://msb-leasing.ru"
            text
          >
            <span class="d-flex flex-column">
              <span class="">О компании</span>
            </span>
          </v-btn>

          <v-btn
            plain
            :ripple="false"
            class="nav-link nav-btn"
            to="/about"
            text
          >
            <span class="d-flex flex-column">
              <span class="">О сервисе</span>
            </span>
          </v-btn>

          <v-btn
            plain
            :ripple="false"
            class="nav-link nav-btn"
            to="/feedback"
            text
          >
            <span class="d-flex flex-column">
              <span class="">Обратная связь</span>
            </span>
          </v-btn>
          <v-btn
            v-if="showLogin"
            plain
            :ripple="false"
            class="nav-link nav-btn nav-link__login"
            to="/login"
            text
          >
            <span class="d-flex flex-column">
              <span class="mr-5">Войти</span>
            </span>
          </v-btn>
          <v-btn
            plain
            :ripple="false"
            class="nav-link nav-btn"
            style="padding-right: 0;"
            href="/feedback"
            text
          >
            <span class="d-flex flex-column">
              <span
                class=""
                style="font-size: 14px;font-weight: 600!important;letter-spacing: 0.1em;"
                >8 (800) 707-36-37</span
              >
              <a
                href="/Call"
                class="nav-link__subtitle"
                style="color: #1CA263!important;"
                >Заказать звонок</a
              >
            </span>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-spacer v-if="this.$vuetify.breakpoint.smAndDown"></v-spacer>
      <v-menu
        v-if="this.$vuetify.breakpoint.smAndDown"
        class="hidden-md-and-up"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon class="">fas fa-bars</v-icon>
          </v-btn>
        </template>

        <v-list>
          <template v-if="!showLogin">
            <v-list-item class="mobile_nav_item" :to="'Dossier'">
              Мои заявки
            </v-list-item>
            <v-list-item class="mobile_nav_item" :to="'Payments'">
              Мои выплаты
            </v-list-item>
            <v-list-item class="mobile_nav_item" :to="'Profile'">
              Профиль
            </v-list-item>
            <v-list-item class="mobile_nav_item" :to="'Relation'">
              Агентский договор
            </v-list-item>
            <v-list-item class="mobile_nav_item" :to="'Rates'">
              Тарифы выплат
            </v-list-item>

            <v-list-item  class="mobile_nav_item "  @click="$emit('logout')">
              Выйти
            </v-list-item>
          </template>

          <v-list-item
            class="mobile_nav_item mobile_nav_item_border"
            href="https://msb-leasing.ru"
            target="_blank"
          >
            О компании</v-list-item
          >
          <v-list-item class="mobile_nav_item " to="/about">
            О сервисе
          </v-list-item>
          <v-list-item v-if="showLogin" class="mobile_nav_item " to="/login">
            Войти
          </v-list-item>




          <v-list-item
            class="mobile_nav_item mobile_nav_item_border"
            href="tel:88007073637"
          >
            8 (800) 707-36-37
          </v-list-item>
          <v-list-item class="mobile_nav_item" to="/Call">
            Заказать звонок
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
<!--    <div class="mx-10 mb-5" v-if="this.$vuetify.breakpoint.mdAndUp && !this.$route.meta.landing">-->
<!--      <div class="konkurs">-->
<!--        <img v-if="!konkurs_opened" v-on:click="konkurs_opened = true"  src="/konkurs/inner_fold.png" style="width: 100%;cursor: pointer"/>-->
<!--        <img v-if="konkurs_opened"  v-on:click="konkurs_opened = false"  src="/konkurs/inner.jpg" style="width: 100%;cursor: pointer"/>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="" v-if="this.$vuetify.breakpoint.xs && !this.$route.meta.landing">-->
<!--      <div class="konkurs">-->
<!--        <img v-if="!konkurs_opened" v-on:click="konkurs_opened = true"  src="/konkurs/mobile_fold1.png" style="width: 100%;cursor: pointer"/>-->
<!--        <img v-if="konkurs_opened"  v-on:click="konkurs_opened = false"  src="/konkurs/inner_mobile1.jpg" style="width: 100%;cursor: pointer"/>-->
<!--      </div>-->
<!--    </div>-->



    <div
      class="mobile_buttons"
      v-if="this.$vuetify.breakpoint.smAndDown && !this.$route.meta.landing"
    >

      <router-link
        class="v-btn primary mobile_buttons_item"
        to="/create-leasing"
      >
        Новая заявка +</router-link
      >
      <router-link class="v-btn mobile_buttons_item" to="Calc"
        >Калькулятор</router-link
      >
    </div>

    <link rel="preload" as="image" href="/landing/ico_login_hover.png" />
  </div>
</template>

<script>
import img from "../assets/img/logo_msb_green.png";

export default {
  props: {
    isLoggedIn: {
      type: Boolean
    }
  },
  data: function() {
    return {
      konkurs_opened:true,
      img,
      menu: [
        { title: "О компании", url: "https://msb-leasing.ru" },
        { title: "О сервисе", url: "/about" },
        { title: "Обратная связь", url: "/feedback" },
        { title: "Войти", url: "/login" },
        {
          title: "8 (800) 707-36-37",
          url: "/Call",
          subtitle: "Заказать звонок",
          suburl: "/Call"
        }
      ]
    };
  },
  computed: {
    showLogin() {
      if (this.$route.meta.landing) return true;
      if (!this.$route.meta.landing && !this.$store.getters.isLoggedIn)
        return true;
      return false;
    }
  },

  created() {
    this.getNewCommentsCount();
    //
    // // в ЛК каждые 10 сек проверяем -
    // есть ли новое (комменты к заявке или  выплаты)
    if (this.$route.meta.requiresAuth) {
      setInterval(() => {
        this.getNewCommentsCount();
      }, 10000);
    }
  },
  methods: {
    getNewCommentsCount() {
      this.$store.dispatch("getNewCommentsCount").catch(err => {
        console.log(err);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.konkurs{
  position: relative;
}
.konkurs_close{
  color: rgba(255, 255, 255, 0.66);
  position: absolute;
  right: 20px;
  top: 2px;
  font-size: 18px;
  cursor: pointer;
}
.konkurs__opened{
  //height: 295px;
  //background-image: url(/konkurs/bg.png);
  //background-size: cover;
}
.nav_wrapper_cabinet {
  margin: auto;
  width: 100%;
  max-width: 1323px;

  //padding-right: 11px;

  @media (min-width: 900px) {
    padding-left: 20px;
  }
}
.nav-bar-wrapper {
  z-index: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4) !important;
  flex-grow: 0;

  margin: auto;
  width: 100%;
  max-width: 1510px;

  .v-toolbar__content {
    height: 100%;
  }
}

.nav-bar-shadow {
  box-shadow: 5px 8px 12px #f1f1f1 !important;
}

.logo {
  width: 120px;
  height: auto;

  @media (min-width: 576px) {
    padding-left: 16px;
    width: 118px;
    height: auto;
  }
}

.nav-divider {
  background: rgba(255, 255, 255, 0.4);
  min-height: 30%;
  max-height: 30%;
  align-self: center;
}
.nav-btn {
  font-weight: bold !important;
  font-size: 14px !important;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  &:hover {
    //color: #1CA263;
  }
}

.nav-link {
  transition: none !important;
  margin-left: 30px;
  &:hover {
    color: #1ca263;
  }
  &__subtitle {
    text-decoration: none;
    text-align: right;
    font-size: 9px;
    line-height: 15px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #1ca263 !important;
  }
}
.nav-link__login {
  background-image: url("/landing/ico_login2.png");
  background-position-x: 70px;
  background-position-y: 9px;
}
.nav-link__login:hover {
  background-image: url("/landing/ico_login_hover.png");
}

.mobile_buttons {
  text-align: center;
  //margin: auto;
  padding: 10px;
}
.mobile_buttons_item {
  display: inline-block;
  text-decoration: none;
  width: 43%;
  margin: 10px;
  padding: 10px;
  border: 1px solid grey;
  text-align: center;
  text-transform: uppercase;
  font-size: 80%;
}

.mobile_nav_item {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 75%;
  margin: 0 10px;
}
.mobile_nav_item_border {
  border-top: 1px solid #eee;
}
</style>
