<template>
  <v-container>
    <h2>Тарифы выплат</h2>
    <v-breadcrumbs
      class="breadcrumbs"
      :items="[
        {
          text: 'Главная',
          disabled: false,
          href: '/'
        },
        {
          text: 'Тарифы выплат',
          disabled: true
        }
      ]"
    ></v-breadcrumbs>

    <div class="mt-5">
      <b
        >Компания «МСБ-Лизинг» предлагает <br />
        следующие стандартные тарифы агентского вознаграждения:</b
      >
      <v-row class="d-block d-md-flex">
        <v-col class="col-md-7">
          <h4>Лизинг транспорта и техники: 2% от суммы финансирования</h4>
          — Грузовые автомобили и легкий коммерческий транспорт<br />
          — Прицепы к грузовым авто<br />
          — Легковые автомобили<br />
          — Спецтехника (ПСМ)<br />
          — Сельхозтехника (ПСМ)<br />
          — Автобусы<br />

          <h4>Лизинг оборудования: 2% от суммы финансирования</h4>
          — Оборудование /станки<br />
          — Сельхозоборудование / навеска<br />
          — Медицинское оборудование<br />

          <h4>ЖД-транспорт: 1% от суммы финансирования</h4>
          — Вагоны<br />
          — Подвижной состав<br />

          <h4>Возвратный лизинг: 1% от суммы финансирования</h4>
          — Грузовые автомобили и легкий коммерческий транспорт<br />
          — Спецтехника (ПСМ)<br />

          <h4>Лизинг недвижимости: 1% от суммы финансирования</h4>
          — Офисная недвижимость<br />
          — Торговая недвижимость<br />
          — Иной вид коммерческой недвижимости (под рестораны, клиники и пр.)<br />
          — Апартаменты (продавцом и покупателем выступает ЮЛ или ИП)<br />

          <h4>Майнинговое оборудование: 1% от суммы финансирования</h4>
          — Все виды майнингового оборудования<br />

          <v-row no-gutters class="mt-4">
            <v-col cols="1">
              <v-icon color="primary">fas fa-question-circle</v-icon>
            </v-col>
            <v-col>
              <a
                href="https://msb-leasing.ru/news/company_blog/chto-takoe-vozvratnyy-lizing/"
              >
                Узнать подробнее о возвратном лизинге</a
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col class="col-md-5">
          <div class="howto">
            <h4>Какие данные потребуются для подачи стандартной заявки?</h4>
            - ИНН или название компании-клиента<br />
            - стоимость предмета лизинга, ₽<br />
            - имя контактного лица<br />
            -телефон контактного лица<br />
          </div>
          <div class="howto">
            <h4>Как повысить любой из стандартных тарифов на 0.25%?</h4>

            Укажите дополнительные условия сделки:<br />
            - размер аванса <br />
            - срок лизинга <br />
            - отметьте, был ли предмет лизинга был в употреблении и если да, то
            укажите год выпуска <br />
            - загрузите баланс компании-клиента за последний отчетный квартал
            <br />
            - загрузите предложение по предмету лизинга от поставщика <br />
          </div>

          <v-btn :to="form_link" class="mt-16" color="primary" type="submit">
            Отправить заявку
            <v-icon class="ml-2" size="16">fab fa-telegram-plane</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
export default {
  name: "Rates",

  computed: {
    is_pro() {
      return this.$store.state.user.is_pro;
    },
    form_link() {
      return this.is_pro ? "create-leasing-pro" : "create-leasing";
    }
  }
};
</script>

<style lang="scss">
.howto {
  border: 1px solid #cacaca;
  padding: 15px;
  font-size: 90%;
  margin-bottom: 20px;
  border-right: 8px solid $msb-green;
  border-radius: 10px;
  line-height: 130%;
}
.howto h4 {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}
h4 {
  color: $msb-green;
  margin-top: 20px;
  margin-bottom: 8px;
}
</style>
