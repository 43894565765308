<template>
  <v-tooltip
    right
    nudge-right="34"
    max-width="300px"
    color="rgba(0, 0, 0, 0.8)"
  >
    <template v-slot:activator="{ on, attrs }">
      <router-link class="nav-link my-4" :to="href">
        <!--        <span class="nav-link__text">{{ text }}</span> >-->
        <!--        <br class="d-inline d-md-none" />-->
        <span class="nav-link__title">{{ title }}</span>
        <v-icon
          v-bind="attrs"
          v-on="on"
          @click.prevent="() => undefined"
          class="nav-link__icon"
          >fas fa-question-circle</v-icon
        >
      </router-link>
    </template>
    <span v-html="tooltip" class="tooltip_text"></span>
  </v-tooltip>
</template>

<script>
export default {
  name: "HomeLink",
  props: {
    title: {
      type: String
    },
    text: {
      type: String
    },
    tooltip: {
      type: String
    },
    href: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
.tooltip_text {
  @media (max-width: 800px) {
    font-size: 80%;
  }
}
.nav-link {
  position: relative;
  background-color: #1ca263;
  display: inline-block;
  padding: 1rem;
  max-width: 600px;
  border-radius: 5px;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  text-transform: uppercase;

  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1em;

  color: white;

  @media (max-width: 480px) {
    width: calc(100% - 40px);
    padding: 0.7rem;
  }

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
      #1ca263;
  }

  span {
    //display: block;
  }

  &__title {
    font-weight: bold;
  }

  &__text {
  }

  &__icon {
    position: absolute;

    color: #848484;
    opacity: 0.35;
    left: calc(100% + 10px);
    top: calc(50% - 12px);

    @media (max-width: 1200px) {
      left: calc(100% + 15px); //
      top: calc(50% - 12px);
    }

    &:hover {
      opacity: 1;
    }
  }
}
</style>
