var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"zzmax-width":"500px"}},[_c('h2',[_vm._v("Профиль")]),_c('v-breadcrumbs',{staticClass:"breadcrumbs",attrs:{"items":[
      {
        text: 'Главная',
        disabled: false,
        href: '/'
      },
      {
        text: 'Профиль',
        disabled: true
      }
    ]}}),_c('v-snackbar',{attrs:{"timeout":"6000","color":"success","outlined":""},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar_success = false}}},'v-btn',attrs,false),[_vm._v(" x ")])]}}]),model:{value:(_vm.snackbar_success),callback:function ($$v) {_vm.snackbar_success=$$v},expression:"snackbar_success"}},[_vm._v(" Профиль сохранен. ")]),_c('v-form',{ref:"form",staticClass:"mt-5",attrs:{"cl":"","lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.saveProfile.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"rules":_vm.nameRules,"label":"Имя","required":"","error-messages":_vm.nameErrors},on:{"change":_vm.saveProfile},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{attrs:{"value":_vm.phone,"label":"Телефон","required":"","prefix":"+7","type":"tel","disabled":""}}),_c('v-text-field',{attrs:{"label":"E-mail","rules":_vm.emailRules,"required":"","error-messages":_vm.nameErrors,"disabled":_vm.disableEmailField,"hint":_vm.emailHint,"persistent-hint":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary","small":"","type":"submit"}},[_vm._v(" Сохранить ")])],1),_c('v-col',{staticClass:"text-right"},[(_vm.disableEmailField)?_c('v-btn',{attrs:{"outlined":"","color":"primary","small":""},on:{"click":_vm.clickModifyEmail}},[_vm._v(" Изменить e-mail ")]):_vm._e()],1)],1)],1),(_vm.showEmailConfirm)?_c('v-btn',{staticClass:"my-4 text-wrap",attrs:{"color":"info","small":""},on:{"click":_vm.sendEmailConfirm}},[_vm._v(" Отправить письмо с подтверждением ")]):_vm._e(),_c('br')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }