<template>
  <v-container class="justify-center d-flex">
    <v-card class="mx-auto my-12" max-width="400">
      <v-card-title>
        Подтверждение E-mail
      </v-card-title>
      <v-card-text>
        {{ message }}
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ConfirmEmail",
  props: {
    message: {
      type: String,
      default: ""
    }
  },
  computed: {
    isEmailConfirm: function() {
      return this.$store.state.user.isEmailConfirm;
    }
  },
  created() {
    let code = this.$route.params.confirmCode;
    if (code === "") {
      this.message = "Неверный код подтверждения";
      return;
    }
    this.$store
      .dispatch("confirmEmail", code)
      .then(() => {
        this.message = "E-mail успешно подтвержден";

        this.$store.dispatch("user", {});
      })
      .catch(err => {
        this.addErrors(err.data);
      });
  },
  methods: {
    addErrors: function(errors) {
      this.message = "";
      errors.forEach(item => {
        this.message += " " + item.message;
      });
    }
  }
};
</script>

<style scoped></style>
