var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('h2',[_vm._v("Обратный звонок")]),_c('v-breadcrumbs',{staticClass:"breadcrumbs",attrs:{"items":[
      {
        text: 'Главная',
        disabled: false,
        href: '/'
      },
      {
        text: 'Обратный звонок',
        disabled: true
      }
    ]}}),(!_vm.formSubmit)?_c('v-form',{ref:"form",staticClass:"mt-5",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":_vm.nameErrors,"rules":_vm.nameRules,"label":"Имя","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","rules":_vm.phoneRules,"counter":"","label":"Телефон","maxlength":"10","prefix":"+7","required":"","type":"tel"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('v-textarea',{attrs:{"outlined":"","dense":"","label":"Введите сообщение","name":"msg","rows":"3"},model:{value:(_vm.msg),callback:function ($$v) {_vm.msg=$$v},expression:"msg"}}),_c('v-btn',{staticClass:"mr-4 mt-1",attrs:{"disabled":!_vm.valid,"color":"primary","type":"submit"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"16"}},[_vm._v("fas fa-paper-plane")]),_vm._v(" Отправить ")],1)],1):[_c('h3',[_vm._v("Спасибо!")]),_vm._v(" Наши менеджеры свяжутся с вами в течение рабочего времени! ")]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }