<template>
  <v-app>
    <!--    Landing design-->
    <template v-if="$route.meta.landing">
      <!--      <router-view name="navBar" />-->
      <NavBarLanding />
      <v-main ref="mainContainer">
        <router-view />
      </v-main>
    </template>

    <!--  agent Cabinet design -->
    <template v-else>
      <NavBarLanding  @logout="logout"  />

      <v-main
        class="mx-auto main-container"
        style="width:100%;max-width: 1220px"
      >
        <v-container fluid>
          <v-row>
            <v-col class="pr-12 col-md-3 d-none d-md-flex">
              <LeftMenu
                @logout="logout"
                :isLoggedIn="isLoggedIn"
                class="LeftMenu"
                :user="user"
                :new-comments="this.$store.getters.newComments"
                :new-payments="this.$store.getters.newPayments"
              ></LeftMenu>
            </v-col>
            <v-col class="mainCol col-md-9">
              <router-view />
            </v-col>
          </v-row>
        </v-container>
      </v-main>
      <BottomSheet></BottomSheet>
    </template>
  </v-app>
</template>

<script>
import BottomSheet from "@/components/BottomSheet";
import LeftMenu from "@/components/LeftMenu";
import NavBarLanding from "./layout/NavBarLanding";

export default {
  // metaInfo: {
  //   // title: 'x',
  //   titleTemplate: 'Агентский кабинет «МСБ-Лизинг». %s'
  // },
  components: { BottomSheet, LeftMenu, NavBarLanding },
  props: {
    noLoginAvatar: {
      type: String,
      default: require("@/assets/img/avatar.png")
    },
    loginAvatar: {
      type: String,
      default: require("@/assets/img/avatar2.png")
    }
  },
  data() {
    return {};
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
    user: function() {
      return this.$store.getters.user;
    },
    avatar: function() {
      return this.isLoggedIn ? this.loginAvatar : this.noLoginAvatar;
    },
    breadcrumbs: function() {
      return this.$store.state.breadcrumbs;
    },
    showBreadcrumbs: function() {
      return this.breadcrumbs.length;
    }
  },
  watch: {},
  methods: {
    toggleNavbarMobile() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
    },
    logout: function() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    }
  },
  created: function() {
    let that = this;
    this.$http.interceptors.response.use(undefined, function(err) {
      return new Promise(function() {
        if (
          (err.status === 401 || err.response.status === 401) &&
          err.config &&
          !err.config.__isRetryRequest
        ) {
          that.$store.dispatch("logout");
        }
        throw err;
      });
    });
    if (this.isLoggedIn) {
      this.$store.dispatch("user", {}).catch(err => {
        console.log(err);
        console.log(err.response);
      });
    }

    this.$store.dispatch("getFaqPages").catch(err => {
      console.log(err);
      console.log(err.response);
    });
    this.$store.dispatch("getStepPages").catch(err => {
      console.log(err);
      console.log(err.response);
    });
  }
};
</script>
<style lang="scss">
// без scoped т.к использую в других компонентах
.v-navigation-drawer__border {
  border: none !important;
}

.mainCol {
  border: 1px solid #e0e0e0;
  border-radius: 7px;
}

.LeftMenu {
  position: sticky;
  top: 30px;
  height: 80vh;
}

//используется в разных компонентах
.agentLabel {
  font-size: 12px;
  line-height: 110%;
  display: block;
  margin-top: 8px;
}

.agentValue {
  font-size: 110%;
  font-weight: bold;
  display: inline-block;
  padding: 5px 20px;
  //margin-top: 6px;
  border: 1px solid #5bb04f;
  border-radius: 5px;
}

.scroll_suggest {
  position: absolute;
  right: 10px;
  top: 20px;
  font-size: 50px;
  opacity: 0.15;
  transition: all 1s;
  line-height: 100%;
}
</style>
