<template>
  <v-container>
    <v-dialog v-model="isModalPayment" max-width="800px" scrollable>
      <v-card>
        <v-card-title class="text-h6 lighten-2"
          >Заявка на вознаграждение агенту
          &laquo;МСБ-Лизинг&raquo;</v-card-title
        >

        <v-card-text class="p-5">
          <div class="mt-5 payment">
            <!--            <h2>Заявка на вознаграждение агенту &laquo;МСБ-Лизинг&raquo;</h2>-->
            <div class="payment_date text-right">
              {{
                new Date(openedItem.created_at * 1000).toLocaleDateString(
                  "ru-RU"
                )
              }}
            </div>
            <b>Агент</b> <br />
            <!--            Agents::TYPE_PERSON-->
            <template v-if="this.$store.state.user.type == 1">
              {{ this.$store.state.user.name }}<br />
              ИНН: {{ this.$store.state.user.profile.inn }}<br />
              СНИЛС: {{ this.$store.state.user.profile.snils }}<br />
              Паспортные данные:
              {{ this.$store.state.user.profile.passport_serial }}
              {{ this.$store.state.user.profile.passport_number }} выдан
              {{ this.$store.state.user.profile.passport_issue_date }}
              {{ this.$store.state.user.profile.passport_issued }} <br />
              Адрес регистрации:{{ this.$store.state.user.profile.address_legal
              }}<br /><br />
            </template>
            <!--            Agents::TYPE_IP-->
            <template v-else-if="this.$store.state.user.type == 2">
              {{ this.$store.state.user.profile.company }}<br />
              ИНН: {{ this.$store.state.user.profile.inn }}<br />
              ОГРНИП: {{ this.$store.state.user.profile.ogrn }}<br />
              Паспортные данные:
              {{ this.$store.state.user.profile.passport_serial }}
              {{ this.$store.state.user.profile.passport_number }} выдан
              {{ this.$store.state.user.profile.passport_issue_date }}
              {{ this.$store.state.user.profile.passport_issued }} <br />
              Адрес регистрации:{{ this.$store.state.user.profile.address_legal
              }}<br /><br />
            </template>
            <!--            Agents::TYPE_COMPANY-->
            <template v-else-if="this.$store.state.user.type == 3">
              {{ this.$store.state.user.profile.company }}<br />
              ИНН: {{ this.$store.state.user.profile.inn }}<br />
              ОГРНИП: {{ this.$store.state.user.profile.ogrn }}<br />
              Паспортные данные:
              {{ this.$store.state.user.profile.passport_serial }}
              {{ this.$store.state.user.profile.passport_number }} выдан
              {{ this.$store.state.user.profile.passport_issue_date }}
              {{ this.$store.state.user.profile.passport_issued }} <br />
              Адрес регистрации:{{ this.$store.state.user.profile.address_legal
              }}<br /><br />
            </template>

            <div v-if="this.openedItem.has_nds">
              Является плательщиком НДС: да
            </div>
            <div v-else>Является плательщиком НДС: нет</div>
            <div v-if="this.openedItem.has_faktura">
              Счет-фактура предоставлена: да
            </div>

            <br /><br />
            <b>Услуга</b><br />
            <div class="payment_service">
              Клиент: {{ openedItem.orgName }}<br />
              <template v-if="openedItem.is_changed_client">
                Юр. лицо клиента изменено на: {{ openedItem.new_client }} <br />
              </template>

              Реквизиты клиента: <br />
              <div
                v-html="openedItem.bank_details"
                style="white-space: pre-wrap;"
              ></div>
              <br /><br />
              Номер Договора финансовой аренды (лизинга):
              {{ openedItem.contract_number }}<br />
              Дата: {{ openedItem.contract_date }}<br />
              Номер Акта приема-передачи предмета лизинга:
              {{ openedItem.act_number }}<br />
              Дата: {{ openedItem.act_date }}<br />
            </div>
            <b> Сумма вознаграждения: {{ openedItem.amount }}</b
            ><br />
            <span v-if="openedItem.tariff">Тариф: {{ openedItem.tariff }}</span>

            <div class="payment_footer" v-if="openedItem.state == 3">
              Оплата будет произведена в течение 10 банковских дней с момента
              отправки заявки.
            </div>

            <div class="payment_footer" v-if="openedItem.state == 4">
              По заявке выплачено вознаграждение {{ openedItem.payout_at }}
            </div>
          </div>
        </v-card-text>

        <!--        <v-divider></v-divider>-->

        <v-card-actions class="p-5">
          <v-spacer></v-spacer>
          <v-btn
            v-if="openedItem.state == 2"
            color="primary"
            depressed
            @click="sendPayment(openedItem.id)"
          >
            Отправить заявку
          </v-btn>
          <!--          <v-btn-->
          <!--              depressed-->
          <!--              text-->
          <!--              @click="isModalPayment = false"-->
          <!--          >-->
          <!--            Закрыть-->
          <!--          </v-btn>-->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <h2>Мои выплаты</h2>
    <v-breadcrumbs
      class="breadcrumbs"
      :items="[
        {
          text: 'Главная',
          disabled: false,
          href: '/'
        },
        {
          text: 'Профиль',
          disabled: false,
          href: '/Profile'
        },
        {
          text: 'Мои выплаты',
          disabled: true
        }
      ]"
    ></v-breadcrumbs>
    <div style="position: relative">
      <div v-if="!scrolled" class="d-sm-none scroll_suggest">
        &#10140;<br />&#10140;
      </div>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Дата начисления</th>
              <th class="text-left">№</th>
              <th class="text-left">Лизингополучатель</th>
              <!--              <th class="text-left">Тариф</th>-->
              <th class="text-right">
                Сумма <br />
                финансирования (руб.)
              </th>
              <th class="text-right">
                Агентская <br />
                комиссия (руб.)
              </th>
              <th class="text-right">Статусы выплаты</th>
              <th class="text-right">Дата выплаты</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in items"
              :key="item.id"
              :class="{ newPayment: item.not_opened }"
            >
              <td>{{ item.payin_at }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.orgName }}</td>
              <!--              <td>-->
              <!--                {{ item.agentPercent }}%-->
              <!--                                      <br> ({{ item.segment }})-->
              <!--              </td>-->
              <td class="text-right">{{ item.cost }}</td>
              <td class="text-right">{{ item.amount_format }}</td>
              <!--                    <td  class="text-right">{{ item.pay }}</td>-->
              <td
                style="padding: 10px;"
                class="text-right"
                :class="{ payedOut: item.state == 4 }"
              >
                {{ item.stateName }} <br />
                <span class="modalPaymentLink">
                  <span v-if="item.state == 1" class="modalPaymentLink_wait">
                    Ожидается поставка предмета лизинга
                  </span>
                  <span
                    v-else-if="item.state == 2"
                    @click="openPayment(item)"
                    class="modalPaymentLink_send"
                  >
                    Отправьте заявку
                  </span>
                  <span
                    v-else-if="item.state == 3"
                    @click="openPayment(item)"
                    class="modalPaymentLink_view"
                  >
                    Посмотреть заявку
                  </span>
                  <span
                    v-else-if="item.state == 4"
                    @click="openPayment(item)"
                    class="modalPaymentLink_view"
                  >
                    Посмотреть заявку
                  </span>
                </span>
              </td>
              <td class="text-right">{{ item.payout_at }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Payments",
  data: () => ({
    items: [],
    scrolled: false,
    isModalPayment: false,
    isModalPaymentLoading: false,
    openedItem: {}
  }),
  computed: {},
  methods: {
    //  помечаем все заявки просмотренными
    ajaxPaymentsOpened() {
      this.$http.post("/agents/leasing/payments-opened").catch(err => {
        console.log(err);
      });
    },

    openPayment(item) {
      this.openedItem = item;
      this.isModalPayment = true;
    },

    sendPayment(id) {
      this.isModalPaymentLoading = true;
      this.$http
        .post("/agents/leasing/payment", { id: id })
        .then(resp => {
          console.log(resp);
          this.isModalPayment = false;
          this.getItems();
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
        });

      return id;
    },

    getItems: function() {
      let that = this;
      that.$http
        .get("/agents/leasing/payments")
        .then(resp => {
          that.items = resp.data;
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
        });
    }
  },
  mounted() {
    this.getItems();
    let table = document.getElementsByClassName("v-data-table__wrapper")[0];
    table.addEventListener("scroll", () => (this.scrolled = true));

    setTimeout(() => {
      this.ajaxPaymentsOpened(); //  помечаем все заявки просмотренными
    }, 1500);
  }
};
</script>

<style scoped>
.payedOut {
  color: #5bb04f;
  font-weight: bold;
  font-size: 77% !important;
}
.newPayment {
  background: #ecf4eb;
  outline: 1px solid #759371;
}
.modalPaymentLink {
}
.modalPaymentLink_send {
  cursor: pointer;
  font-weight: bold;
  border-bottom: 1px green dashed !important;
  border-bottom-style: dashed !important;
}
.modalPaymentLink_view {
  cursor: pointer;
  color: #928a8a;
  border-bottom: 1px green dashed !important;
  border-bottom-style: dashed !important;
}
.modalPaymentLink_wait {
  color: #928a8a;
}
.payment_service {
  padding-left: 100px;
  margin-bottom: 50px;
}
.payment_footer {
  color: green;
  margin-top: 7px;
}
th {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 60% !important;
}
td {
  font-size: 80% !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.showActions) {
  background: #e1efe2 !important;
}
</style>
