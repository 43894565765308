<template>
  <v-container>
    <h2>Агентский договор</h2>
    <v-breadcrumbs
      class="breadcrumbs"
      :items="[
        {
          text: 'Главная',
          disabled: false,
          href: '/'
        },
        {
          text: 'Агентский договор',
          disabled: true
        }
      ]"
    ></v-breadcrumbs>

    <div v-if="!isPro" class="mt-2">
      <b
        >Присоединение к агентскому договору — обязательное условие для
        получения агентского вознаграждения.</b
      ><br />

      <p>
        Присоединение к агентскому договору — обязательное условие для получения
        агентского вознаграждения.
      </p>
      <p>
        Необходимо присоединиться к договору до момента реализации первой
        сделки. Для рассмотрения заявок и предварительного общения с клиентами,
        присоединяться к договору необязательно.
      </p>

      <p>
        Чтобы присоединиться к договору, необходимо заполнить все данные,
        приведенные в форме ниже и согласиться к присоединению с помощью смс.
        Отправка смс будет приравнена к простой электронной подписи.
      </p>

      <a href="/docs/MSB_agent_dogovor.docx" target="_blank"
        >Ознакомиться с условиями договора</a
      >
      <br />

      <p style="margin-top: 10px;">
        Договор может быть заключен с резидентом Российской Федерации в любом
        статусе: физическое лицо, юридической лицо, индивидуальный
        предприниматель. <br />
        <span style="font-size: 90%;"
          >* Наиболее предпочтительной формой является договор с ИП.</span
        >
      </p>
      <p>
        Для присоединения к договору, необходимо выбрать свой статус, указать
        систему налогообложения и далее заполнять данные согласно форме. После
        отправки вами смс, подтверждающей ваше присоединение, договор уйдет на
        модерацию и форма не будет доступна для редактирования. Скорректировать
        данные можно будет через запрос в службу поддержки.
      </p>
      <p>
        Договоры проходят модерацию в рабочие дни, с 9 до 18 часов по мск. По
        факту прохождения проверки, в интерфейсе вашего кабинета появится
        соответствующее уведомление.
      </p>
    </div>
    <PersonalDataForm></PersonalDataForm>
  </v-container>
</template>

<script>
import PersonalDataForm from "@/components/PersonalDataForm";

export default {
  name: "Relation",
  components: { PersonalDataForm },
  computed: {
    isPro() {
      return this.$store.state.user.is_pro;
    }
  }
};
</script>

<style scoped></style>
