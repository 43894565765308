<template>
  <v-container>
    <h2>Обратный звонок</h2>
    <v-breadcrumbs
      :items="[
        {
          text: 'Главная',
          disabled: false,
          href: '/'
        },
        {
          text: 'Обратный звонок',
          disabled: true
        }
      ]"
      class="breadcrumbs"
    ></v-breadcrumbs>
    <v-form
      v-if="!formSubmit"
      ref="form"
      v-model="valid"
      class="mt-5"
      lazy-validation
      @submit.prevent="submit"
    >
      <v-text-field
        outlined
        dense
        v-model="name"
        :error-messages="nameErrors"
        :rules="nameRules"
        label="Имя"
        required
      ></v-text-field>

      <v-text-field
        outlined
        dense
        :rules="phoneRules"
        v-model="phone"
        counter
        label="Телефон"
        maxlength="10"
        prefix="+7"
        required
        type="tel"
      ></v-text-field>

      <v-textarea
        outlined
        dense
        v-model="msg"
        label="Введите сообщение"
        name="msg"
        rows="3"
      ></v-textarea>
      <v-btn :disabled="!valid" class="mr-4 mt-1" color="primary" type="submit">
        <v-icon class="mr-2" size="16">fas fa-paper-plane</v-icon>
        Отправить
      </v-btn>
    </v-form>
    <template v-else>
      <h3>Спасибо!</h3>
      Наши менеджеры свяжутся с вами в течение рабочего времени!
    </template>
  </v-container>
</template>

<script>
export default {
  name: "Call",
  data: () => ({
    msg: "",
    name: "",
    phone: "",
    formSubmit: false,
    valid: true,
    checkbox: false,
    nameErrors: [],
    emailErrors: [],
    nameRules: [v => !!v || "Введите имя"],
    phoneRules: [
      v => !!v || "Введите телефон",
      v => /^\d+$/.test(v) || "Телефон должен состоять только из цифр",
      v => (v && v.length === 10) || "Телефон должен содержать 10 знаков"
    ]
  }),

  created() {
    this.name = this.$store.state.user.name;
    this.phone = this.$store.state.user.phone;
  },
  methods: {
    // restoreProfile: function () {
    //   this.$store.dispatch("user", {}).then(() => {
    //     this.isEmailChange = false;
    //     this.isSendConfirm = false;
    //   });
    // },
    submit() {
      if (this.$refs.form.validate()) {
        this.createPhoneCall();
        this.formSubmit = true;
      }
    },
    createPhoneCall() {
      const that = this;
      this.$store
        .dispatch("callOrder", {
          name: this.name,
          phone: this.phone,
          msg: this.msg
        })
        .then(() => {
          that.callOrderModal = true;
        })
        .catch(err => {
          console.error(err.data);
        });
    }
  }
};
</script>

<style scoped></style>
