<template>
  <v-container>
    <h2>Пользовательское соглашение</h2>
    <v-breadcrumbs
      :items="[
        {
          text: 'Главная',
          disabled: false,
          href: '/'
        },
        {
          text: 'Пользовательское соглашение',
          disabled: true
        }
      ]"
      class="breadcrumbs"
    ></v-breadcrumbs>

    <div class="v-card__text px-3 pt-5" style="color: rgba(0, 0, 0, 0.6);">
      <div style="font-weight: bold;">
        О порядке использования сайта ООО &laquo;МСБ-Лизинг&raquo;
        (msb-leasing.ru),<br />раздела сайта (интернет-страницы) &laquo;Кабинет
        агента&raquo;
      </div>

      <br />Настоящее Пользовательское соглашение (далее &ndash;
      &laquo;Соглашение&raquo;) разработано ООО &laquo;МСБ-Лизинг&raquo; (далее
      также &ndash; Компания, Общество, Принципал) и определяет условия
      использования интернет-страницы &laquo;Кабинет агента&raquo;
      интернет-сайта msb-leasing.ru, а также права и обязанности его
      Пользователей (далее также &ndash; Агентов) и Компании (далее при
      совместном упоминании &ndash; &laquo;Стороны&raquo;).<br />Зарегистрировавшись
      на интернет-странице, в личном кабинете посредством Сайта
      https://msb-leasing.ru, а также воспользовавшись любой функциональной
      возможностью сайта, страницы, Пользователь выражает свое безоговорочное
      согласие по принятию данного Соглашения. Соглашение действительно в
      электронной форме (электронный документ).<br /><br />Регистрация лица в
      качестве Пользователя, Агента на интернет-странице, в личном кабинете
      (Кабинете Агента) посредством Сайта https://agent.msb-leasing.ru означает
      заключение договора присоединения об оказании услуг по поиску клиентов
      (является Приложением к настоящему Соглашению). Договор об оказании услуг
      по поиску клиентов не является письменной публичной офертой Компании в
      соответствии со ст. 437 ГК РФ, Компания вправе по своему усмотрению
      отказать в заключении Договора без объяснения причин такого отказа.<br /><br />С
      момента заключения (принятия условий) пользовательского соглашения и
      договора об оказании услуг по поиску клиентов, Агент получает доступ к
      полному функционалу Кабинета агента.<br />Агент несет ответственность за
      достоверность и полноту информации и документов, предоставленных Компании
      или размещенных в Кабинете агента, и самостоятельно несет риск наступления
      последствий в случае предоставления неполной и/или недостоверной
      информации, документов.<br /><br />Настоящее Пользовательское соглашение и
      иные документы, правила, договоры Компании, размещенные на сайте Компании,
      в Кабинете агента, являются обязательными для Пользователей, Агентов.
      <br /><br />
      1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ<br />Компания (Принципал, Общество) &ndash;
      Общество с ограниченной ответственностью &laquo;МСБ-Лизинг&raquo; (ИНН
      6164218952, ОГРН 1046164002983, юридический адрес г.Ростов-на-Дону, пр.
      Ворошиловский, 62).<br />Сайт &ndash; сайт в сети &laquo;Интернет&raquo;,
      располагающийся по адресу - msb-leasing.ru, принадлежащий Компании.<br />Кабинет
      агента &ndash; раздел сайта, в котором Пользователь регистрируется в
      качестве агента с целью оказания Компании как Принципалу агентских услуг
      по поиску и привлечению лиц, желающих заключить с Компанией договор
      финансовой аренды (лизинга).<br />&nbsp;Пользователь &ndash; любое лицо,
      принявший условия настоящего Соглашения в полном объеме и соответствующее
      его требованиям и требованиям Компании, потенциальный Агент, прошедший
      первоначальную регистрацию в соответствующем разделе Сайта.<br />Агент
      &ndash; Пользователь, предоставивший полные данные, прошедший полную
      регистрацию в соответствующем разделе сайта и являющийся стороной договора
      об оказании услуг по поиску клиентов, заключенного этим лицом с Компанией
      путем присоединения, по которому Агент обязуется за вознаграждение
      совершить по поручению Компании (принципала) юридические и иные действия,
      а именно: поиск и привлечение потенциальных клиентов, желающих заключить с
      Компанией договор финансовой аренды (лизинга) в качестве
      лизингополучателей.<br />Договор оказания услуг по поиску клиентов (далее
      также Договор) &ndash; договор, заключаемый между Компанией и Агентом
      (путем присоединения), согласно которому Компания поручает, а Агент за
      вознаграждение берет на себя обязательство совершать юридические и иные
      действия, а именно: поиск и привлечение потенциальных клиентов, желающих
      заключить с Обществом договор финансовой аренды (лизинга) в качестве
      лизингополучателей.<br />Информация, предоставляемая Пользователем/Агентом
      &ndash; документы и сведения, содержащие различную информацию о
      Пользователе/Агенте, а также о третьих лицах (физических и юридических
      лицах), в том числе персональные данные таких лиц.<br />Персональные
      данные &ndash; любая информация, относящаяся к прямо или косвенно
      определенному или определяемому физическому лицу (субъекту персональных
      данных).<br />&laquo;Cookies&raquo; &mdash; небольшой фрагмент данных,
      отправленный веб-сервером и хранимый на компьютере пользователя, который
      веб-клиент или веб-браузер каждый раз пересылает веб-серверу в
      HTTP-запросе при попытке открыть страницу соответствующего сайта. Типы
      cookies-файлов: - сеансовые cookies &ndash; временные файлы, хранение
      которых на компьютере Пользователя прекращается по окончанию сессии, когда
      веб-браузер закрыт или Пользователь покинул сайт; - постоянные cookies -
      временные файлы, хранение которых на компьютере Пользователя прекращается
      по истечению установленного срока &laquo;годности&raquo; файла или пока не
      будут удалены Пользователем.<br />&laquo;IP-адрес&raquo; &mdash;
      уникальный сетевой адрес узла в компьютерной сети, построенной по
      протоколу IP. <br /><br />
      2. РЕГИСТРАЦИЯ И АВТОРИЗАЦИЯ ПОЛЬЗОВАТЕЛЯ<br />
      2.1. Для начала работы в Кабинете агента, лицо, намеренное стать Агентом
      Компании и заключить договор на оказание услуг по поиску клиентов, должно
      зарегистрироваться в соответствующем разделе сайта Компании &ndash;
      Кабинете агента, предоставив Компании свои данные: &laquo;логин&raquo; -
      идентификатор пользователя (в виде Фамилии и имени) и номер мобильного
      телефона, для входа и работы в Кабинете агента в качестве Пользователя.<br />При
      регистрации Пользователю на указанный им при регистрации номер телефона
      приходит код подтверждения &ndash; SMS-ключ (трехзначное или более- число
      или буквенно-числовое сочетание), который Пользователь должен ввести в
      соответствующую графу, тем самым подтвердить данные, указанные при
      регистрации и завершить регистрацию.<br />Такая регистрация позволит
      Пользователю осуществлять вход в Личный кабинет агента, ознакомиться с
      функционалом Кабинета агента, а также размещать, сохранять документы и
      сведения потенциального клиента с целью определения Компанией возможности
      заключения с таким клиентом Договора финансовой аренды (лизинга).<br />Осуществляя
      регистрацию для начала работы в Кабинете агента и предоставляя свои
      данные, Пользователь выражает согласие на обработку Компанией его
      персональных данных.<br /><br />
      2.2. Для получения возможности полноценного использования Кабинета Агента,
      Пользователь обязан предоставить полную информацию, необходимую для
      идентификации, проверки Пользователя на предмет его соответствия
      требованиям законодательства и правилам Компании. Предоставление полной
      информации и данных Пользователя производится путем заполнения всех
      обязательных полей регистрационной формы и приложения цифровых образов
      документов и пр.<br />Пользователь может получить статус Агента только
      после полной регистрации и заключения Договора об оказании услуг по поиску
      клиентов (путем присоединения/подачи заявления на присоединение к
      договору).<br />Производя полную регистрация и предоставляя свои
      персональные данные, Пользователь, Агент выражает свое согласие на
      обработку Компанией его персональных данных. Срок такого согласия &ndash;
      неопределенный. Согласие может быть отозвано по личному заявлению
      Пользователя, Агента.<br />При совершении регистрации в Кабинете агента,
      Компания осуществляет проверку данных Пользователя, Агента. При проведении
      такой проверки Компания вправе по своему усмотрению запросить
      предоставления отдельных документов или сведений.<br />Информация,
      запрашиваемая Компанией на этапе регистрации необходима Компании для
      проведения идентификации, проверки и установления соответствия
      Пользователя требованиям закона и требованиям Компании.<br />На основании
      результатов такой проверки Компания принимает решение о заключении или
      отказе в заключении Договора оказания услуг по поиску клиентов с
      Пользователем, и дальнейшем использовании таким Пользователем сайта
      Компании.<br />После принятия положительного решения Компанией о
      заключении с Пользователем &nbsp;Договора оказания услуг по поиску
      клиентов, Пользователю присваивается статус Агента и он получает право
      полноценного использования Кабинета агента и соответствующих разделов
      сайта Компании.<br />В случае принятия Компанией решения об отказе в
      заключении Договора оказания услуг и присвоения статуса Агента,
      Пользователь не приобретает права на использование соответствующего
      раздела сайта и Кабинета агента.<br />В случае, если при проведении
      проверки Компанией были запрошены дополнительные документы или сведения от
      Пользователя, принятие решения о присвоении статуса Агента или об отказе в
      этом, откладывается до момента получения запрошенных документов, сведений
      и до принятия соответствующего решения Компанией.<br />Регистрационная
      форма Сайта может запрашивать у пользователя дополнительную информацию, в
      зависимости от потребности Компании.<br />В случае верного
      последовательного выполнения всех регистрационных действий, на Сайте
      создается Кабинет агента, которая может иметь уникальный сетевой адрес.
      Пользователь вправе зарегистрировать не более одного Кабинета агента.<br />
      2.3. Агентом может быть юридическое лицо, созданное в соответствии с
      законодательством РФ, индивидуальный предприниматель, зарегистрированный в
      соответствии с законодательством РФ, физическое лицо, являющееся
      гражданином РФ, достигшее 18- летнего возраста и обладающее полной
      дееспособностью.<br />
      2.4. Заключение Договора оказания услуг по поиску клиентов осуществляется
      путем подписания Пользователем заявки на присоединение к Договору с
      использованием простой электронной подписи. При подаче заявке
      Пользователем может использоваться усиленная квалифицированная подпись, в
      случае ее наличия у Пользователя. <br /><br />
      3. ПРЕДМЕТ СОГЛАШЕНИЯ<br />3.1. Компания на безвозмездной основе
      предоставляет доступ к Сайту и Кабинету агента Пользователям, а
      Пользователь в свою очередь по умолчанию гарантирует, что предоставляемая
      Компании с помощью Сайта Информация, в том числе персональные данные,
      предоставляется Компании при наличии согласия Пользователя или с согласия
      третьих лиц.<br />3.2. Пользователь/Агент гарантирует, что Информация,
      предоставленная Компании с помощью Сайта, в том числе относящаяся к
      персональным данным, является полной, точной и достоверной во всех
      отношениях.<br />Пользователь/Агент подтверждает, что представленная
      информация соответствует действительности, не возражает против проверки ее
      достоверности, в том числе с использованием услуг других операторов.<br />Пользователь/Агент
      подтверждает и гарантирует, что получены все необходимые согласия лиц о
      предоставлении (передаче, сообщении и пр.) ООО &laquo;МСБ-Лизинг&raquo; (и
      иным третьим лицам, уполномоченными ООО &laquo;МСБ-Лизинг&raquo; и/или с
      которыми у ООО &laquo;МСБ-Лизинг&raquo; установлены гражданско-правовые
      отношения) информации о них, в том числе относящейся к персональным данным
      физических лиц.<br />Настоящим Пользователь/Агент признает и подтверждает,
      что настоящее согласие считается данным любым третьим лицам,
      уполномоченными ООО &laquo;МСБ-Лизинг&raquo; и/или с которыми у ООО
      &laquo;МСБ-Лизинг&raquo; установлены гражданско-правовые отношения, и
      такие третьи лица имеют право на обработку предоставленных данных на
      основании настоящего согласия при условии соблюдения ограничений
      относительно допустимых целей их обработки. <br /><br />
      4. ПРАВА И ОБЯЗАННОСТИ СТОРОН<br />
      4.1. Пользователи/Агенты вправе:<br />
      4.1.1. Использовать инструменты Сайта и Кабинета агента с целью
      последующих поиска и привлечения потенциальных клиентов для Компании.<br />
      4.1.2. Обращаться к Компании с предложениями по улучшению работы Сайта,
      Кабинета агента в целом и отдельных его сервисов в частности.<br />
      4.1.3. Просить разъяснений от Компании в случае, если с точки зрения
      Пользователя/Агента, Компания неправомерно заблокировала его учетную
      запись или ограничила доступ к сервисам Сайта/Кабинета агента.<br />
      4.1.4. Обращаться к Компании с жалобами на других Пользователей/Агентов, в
      случае если их действия нарушают положения настоящего Соглашения.<br />
      4.2. Пользователи/Агенты обязаны:<br />
      4.2.1. До момента регистрации на Сайте ознакомиться с текстом данного
      Соглашения, а также периодически проверять наличие изменений Соглашения и
      его приложений. Настоящее Соглашение может быть изменено и/или дополнено
      Компанией в одностороннем порядке без какого-либо специального
      уведомления. Настоящее Соглашение является открытым и общедоступным
      документом. Компания рекомендует Пользователям/Агентам регулярно проверять
      условия настоящего Соглашения на предмет его изменения и/или дополнения.
      Продолжение использования Сайта/Кабинета агента Пользователем/Агентом
      после внесения изменений и/или дополнений в настоящее Соглашение означает
      принятие и согласие Пользователя/Агента с такими изменениями и/или
      дополнениями.<br />
      4.2.2. В случае изменения персональных/идентификационных данных, указанных
      при регистрации, Пользователь/Агент обязуется уведомить об этом Компанию
      путем внесения изменений в личном кабинете (или ином специальном разделе
      сайта), в письменном виде или по электронной почте info@msb-leasing.ru в
      течение 7 (семи) календарных дней с момента изменения. В противном случае
      Компания не несет ответственности за получение Пользователем/Агентом
      какой-либо информации, для которой необходимы эти данные, а равно
      совершения/несовершения действий в пользу или в отношении
      Пользователя/Агента.<br />
      4.2.3. Незамедлительно сообщить о любой несанкционированной попытке
      доступа к своему Кабинету агента, а также об утрате логина и пароля (в
      случае его установления) по электронной почте info@msb-leasing.ru. Если
      Пользователем/Агентом не доказано обратное, любые действия, совершенные с
      использованием его логина и пароля, считаются совершенными соответствующим
      Пользователем/Агентом.<br />
      4.2.4. При регистрации на Сайте (создании Кабинета агента) Пользователь
      обязан предоставить Компании необходимую, достоверную и актуальную
      информацию для формирования Кабинета агента (Персональная страница
      Пользователя), включая уникальные для каждого Пользователя логин/пароль
      доступа к Кабинету или логин/sms-подтверждение (в зависимости от функции
      Сайта/Страницы сайта. &nbsp;<br />
      4.2.5. Предоставлять информацию о третьих лицах (потенциальных клиентах) с
      соблюдением Законодательства РФ.<br />
      4.2.6. Пользователь соглашается не предпринимать действий, которые могут
      рассматриваться как нарушающие российское законодательство или нормы
      международного права, в том числе в сфере интеллектуальной собственности,
      авторских и/или смежных правах, а также любых действий, которые приводят
      или могут привести к нарушению нормальной работы Сайта, сервисов Сайта и
      Кабинета агента.<br />
      4.2.7. Пользователь предупрежден о том, что Компания не несет
      ответственности за посещение и использование им внешних ресурсов, ссылки
      на которые могут содержаться на сайте.<br />
      4.2.8. Пользователь принимает положение о том, что все материалы и сервисы
      Сайта или любая их часть могут сопровождаться рекламой. Пользователь
      согласен с тем, что Компания не несет какой-либо ответственности и не
      имеет каких-либо обязательств в связи с такой рекламой.<br />
      4.3. Компания вправе:<br />
      4.3.1. В любое время изменять оформление Сайта/Кабинета агента, его
      содержание, список сервисов, изменять или дополнять используемые скрипты,
      программное обеспечение и другие объекты, используемые или хранящиеся на
      Сайте/Кабинете агента, любые серверные приложения с предварительным
      уведомлением или без такового.<br />
      4.3.2. Удалять Кабинет агента Пользователя/Агента, что означает
      автоматическое удаление всей информации, размещенной в нем, а также всей
      информации Пользователя/Агента, введенной при регистрации на Сайте. После
      удаления персональной страницы Пользователь теряет права доступа к
      Сайту/Кабинету агента.<br />
      4.3.3. Распоряжаться статистической информацией, связанной с
      функционированием Сайта/Кабинета агента, а также информацией
      Пользователей/Агентов для обеспечения адресного показа рекламной
      информации различным аудиториям Пользователей Сайта. Для целей организации
      функционирования и технической поддержки Сайта, а также исполнения
      настоящих правил, Компания имеет техническую возможность доступа к
      Кабинетам агентов, которую реализует только в случаях, установленных
      настоящим Соглашением.<br />
      4.3.4. Направлять Агенту информацию о развитии Сайта/Кабинета агента и его
      сервисов, а также рекламировать собственную деятельность и Услуги.<br />
      4.3.5. Размещать на Сайте рекламу, в том числе внедрять ее в контент,
      размещенный Пользователями/Агентами.<br />
      4.3.6. По своему усмотрению ограничить доступ к любой информации,
      размещенной Пользователем/Агентом; удалить информацию, размещенную
      Пользователем/Агентом, а также учетную запись Пользователя/Агента.
      Удаление Кабинета Агента (учетной записи) может быть произведено Компанией
      в случае нарушения Пользователем/Агентом условий, указанных в п. 4.2.4,
      4.2.5, 4.2.6. настоящего соглашения и/или совершения иных действий, в
      результате которых Компании причинен ущерб, в том числе репутационный, или
      Агентом допущено нарушение прав Компании. Удаление Кабинета агента
      означает прекращение каких-либо отношений Компании и
      Пользователя/Агента.<br />
      4.3.7. Отказать в регистрации Пользователю, учетная запись которого была
      ранее удалена за нарушение условий Соглашения.<br />
      4.4. Компания обязана:<br />
      4.4.1. Обеспечивать функционирование и работоспособность Сайта/Кабинета
      агента и оперативно восстанавливать его работоспособность в случае
      технических сбоев и перерывов.<br />
      4.4.2. Осуществлять защиту учетной записи Пользователя/Агента от
      неправомерного доступа, уничтожения размещенной Пользователем/Агентом
      информации, а также от иных неправомерных действий в отношении такой
      информации всеми находящимися в ее распоряжении техническими средствами.
      <br /><br />
      5. ЛИЧНЫЙ КАБИНЕТ АГЕНТА<br />
      5.1. Для каждого зарегистрированного Пользователя на сайте Компании
      создается Личный кабинет агента, в котором отображается информация о
      Пользователе/Агенте, его действиях, статусе заявок на заключение
      потенциальными клиентами с Компанией договора финансовой аренды (лизинга),
      оказанных Агентом услугах и выплатах Агенту.<br />
      5.2. В Личном кабинете агента содержаться электронные документы или
      цифровые образы документов, подтверждающие действия Агента или относящиеся
      к заявке потенциального клиента.<br />
      5.3. &nbsp; В Личном кабинете агента может содержаться форма обратной
      связи Агента с Компанией.<br />
      5.4. При заключении Компанией договора финансовой аренды (лизинга) с
      клиентом, привлеченным Агентом, в Личном кабинете агента отражается статус
      такого договора, а также сумма вознаграждения Агента, полагающаяся Агенту
      к выплате.<br /><br />
      6. ОТВЕТСТВЕННОСТЬ<br />
      6.1. При неисполнении или ненадлежащем исполнении Сторонами обязательств,
      принятых на себя в соответствии с настоящим Соглашением, стороны несут
      ответственность в соответствии с действующим законодательством РФ.<br />
      6.2. За ущерб, причиненный Стороне, другая Сторона несет ответственность в
      полном размере в соответствии с действующим законодательством РФ.<br /><br />
      7. ПРОЧИЕ УСЛОВИЯ<br />7.1. Ничто в Соглашении не может пониматься как
      установление между Пользователем и Компанией агентских отношений,
      отношений товарищества, отношений по совместной деятельности, отношений
      личного найма, либо каких-то иных отношений, прямо не предусмотренных
      Соглашением.<br />7.2. Использование материалов и сервисов Сайта и
      Кабинета агента регулируется нормами действующего законодательства РФ.<br />7.3.
      Все возможные споры, вытекающие из настоящего Соглашения или связанные с
      ним, подлежат разрешению в соответствии с действующим законодательством
      Российской Федерации.<br />7.4. Компания вправе в любое время в
      одностороннем порядке изменять условия настоящего Соглашения. Такие
      изменения вступают в силу с момента размещения новой версии Соглашения на
      сайте. При несогласии Пользователя с внесенными изменениями он обязан
      отказаться от доступа к Сайту, прекратить использование материалов и
      сервисов Сайта.<br /><br />
      8. ЭЛЕКТРОННЫЙ ДОКУМЕНТООБОРОТ<br />
      8.1. Настоящее Пользовательское соглашение устанавливает признание
      Сторонами (Пользователем, Агентом и Компанией) использование Простой
      электронной подписи (ПЭП) или Квалифицированной электронной подписи (КЭП)
      (при ее наличии у Пользователя) при электронном взаимодействии между
      сторонами.<br />
      8.2. Признание использования Простой электронной подписи при электронном
      взаимодействии, в том числе при заключении Договора на оказание услуг по
      поиску клиентов, является обязательным условием присоединения к настоящему
      Соглашению. Регистрируясь в качестве Пользователя/Агента на сайте, при
      регистрации Кабинета агента, Пользователь подтверждает, что ознакомлен с
      условием об электронном документообороте и безоговорочно с ним
      согласен.<br />
      8.3. Лицо, создающее электронный документ в Кабинете агента и
      подписывающее такой документ ПЭП, определяется как лицо,
      зарегистрированное в соответствующем разделе Сайта, Кабинете агента как
      Пользователь или Агент, авторизованное Компанией/Сайтом, которому
      принадлежит номер мобильного телефона, использованный при
      регистрации/авторизации на Сайте.<br />
      8.4. В целях генерации кода ПЭП и подписания электронного документа Сайт
      (Компания) направляет Пользователю специальный одноразовый цифровой код
      подтверждения - SMS-ключ, который направляется Пользователю/Агенту
      посредством смс-сообщения на номер мобильного телефона, указанный при
      регистрации.<br />
      8.5. Простая электронная подпись содержится в коде самого электронного
      документа (в электронном документе) и подтверждает подписание такого
      электронного документа Пользователем/Агентом. Электронный документ
      содержит информацию, указывающую на лицо, создавшее и/или отправившее
      электронный документ.<br />
      8.6. Электронный документ, подписанный Пользователем/Агентом простой ЭП,
      признается документом, подписанным собственноручно.<br />
      8.7. Проверка ПЭП, которой подписан документ, осуществляется
      программно-техническим комплексом Сайта путем сверки данных кода ПЭП с
      данными, содержащимися в системе генерации SMS-ключей с проверкой
      совпадения ID Пользователя/Агента.<br /><br />
      9. УСЛОВИЯ ИСПОЛЬЗОВАНИЯ COOKIES-файлов<br />6.1. Cookie-файл &ndash;
      файл, содержащий небольшой объем данных, который отправляется веб-сайтом
      (Сайтом Компании) и хранится на компьютере Пользователя.<br />6.2.
      Cookies-файлы используются для настройки содержания сайта с предпочтениями
      Пользователя, поддержания сеанса после входа в систему, создания
      статистики в целях улучшения веб-сайта.<br />6.3. Компания на своем сайте
      использует следующие виды cookies: - используемые в целях безопасности; -
      используемые в целях записи параметров и настроек интерфейса
      пользователя.<br />6.4. Во многих случаях веб-браузеры позволяют cookies
      хранится на компьютере Пользователю по умолчанию. Настройки браузера,
      касающиеся хранения и работы с файлами cookies, могут быть изменены
      Пользователем.<br />6.5. Ограничение в использование cookies файлов может
      отразиться на эффективности пользования сайтом Компании.<br />6.6. Файлы
      cookies, отправленные с сайта Компании, могут также быть использованы
      сайтами компаний-партнеров.
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Agreement",
  data: () => ({}),

  methods: {}
};
</script>

<style lang="scss">
.howto {
  border: 1px solid #cacaca;
  padding: 15px;
  font-size: 90%;
  margin-bottom: 20px;
  border-right: 8px solid $msb-green;
  border-radius: 10px;
  line-height: 130%;
}
.howto h4 {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}
h4 {
  color: $msb-green;
  margin-top: 20px;
  margin-bottom: 8px;
}
</style>
