<template>
  <div>
    <v-radio-group v-model="selectedItem" style="text-transform:uppercase;">
      <v-radio
        v-for="(item, i) in items"
        :key="i"
        @click="changeAgentType(item)"
        :disabled="item.disable"
        :value="item.type"
        :label="item.text"
      ></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: "ChangeAgentType",
  data: () => ({
    selectedItem: null,
    items: [
      {
        text: "Физическое лицо",
        icon: require("@/assets/img/agent_type_person.png"),
        type: 1,
        disable: false
      },
      {
        text: "Индивидуальный предприниматель",
        icon: require("@/assets/img/agent_type_ip.png"),
        type: 2,
        disable: false
      },
      {
        text: "Юридическое лицо",
        icon: require("@/assets/img/agetnt_type_company.png"),
        type: 3,
        disable: false
      }
    ],
    isSendRequest: false
  }),
  computed: {
    userType: function() {
      return this.$store.state.user.type;
    }
  },
  methods: {
    changeAgentType: function(item) {
      console.log(item.type);
      this.$http({
        url: "/agents/profile/change-type?type=" + item.type,
        method: "GET"
      })
        .catch(err => {
          console.log(err);
          console.log(err.response);
        })
        .then(() => {
          this.$store.dispatch("user", {});
        });
    }
  },
  created() {
    this.selectedItem = this.userType - 1;
  }
};
</script>

<style scoped></style>
