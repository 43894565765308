import Vue from "vue";
import Vuetify from "vuetify/lib";
import ru from "vuetify/es5/locale/ru";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#5BB04F", // МСБ фирменный зеленый
        secondary: "#2f3338",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#1868c1",
        success: "#1ca263",
        warning: "#FFC107"
      }
    }
  },
  lang: {
    locales: { ru },
    current: "ru"
  },
  icons: {
    iconfont: "fa"
  }
});
