import Vue from "vue";
import Vuex from "vuex";
import Axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  state: {
    status: "",
    token: localStorage.getItem("token") || "",
    user: {
      name: "",
      authToken: "",
      phone: "",
      email: "",
      isEmailConfirm: false,
      referralCode: "",
      helpPhone: "",
      state: null,
      type: null,
      user_id: null,
      contract_state: null
    },
    newComments: 0,
    newPayments: 0,
    bottomSheetMessage: "",
    breadcrumbs: [],
    faqPages: [],
    stepPages: []
  },
  getters: {
    isLoggedIn: state => !!state.token,
    isShowBottomSheet: state => !!state.bottomSheetMessage,
    authStatus: state => state.status,
    user: state => state.user,
    newComments: state => state.newComments,
    newPayments: state => state.newPayments
  },
  mutations: {
    send_request(state) {
      state.status = "loading";
    },
    auth_success(state, data) {
      state.status = "success";
      state.token = data.token;
      state.user = data.user;
      // todo вынести. (куда?)
      window.ym(81800317, "userParams", {
        name: data.user.name,
        user_id: data.user.user_id
      });
    },
    send_error(state) {
      state.status = "error";
    },
    send_success(state) {
      state.status = "success";
    },
    auth_code_send(state) {
      state.status = "send_code";
    },
    logout(state) {
      state.status = "";
      state.token = "";
    },
    updateUser(state, user) {
      state.status = "success";
      state.user = user;
    },
    showBottomSheetMessage(state, msg) {
      state.bottomSheetMessage = msg;
    },
    closeBottomSheetMessage(state) {
      state.bottomSheetMessage = "";
    },
    setBreadCrumbs(state, items) {
      state.breadcrumbs = items;
    },
    setStepPages(state, items) {
      state.stepPages = items;
    },
    setFaqPages(state, items) {
      state.faqPages = items;
    },
    setNewComments(state, count) {
      state.newComments = parseInt(count.comments_count);
      state.newPayments = parseInt(count.payments_count);
    }
  },
  actions: {
    sendConfirmCode({ commit }, phone) {
      return new Promise((resolve, reject) => {
        commit("send_request");
        Axios({
          url: process.env.VUE_APP_API_URL + "/agents/auth/send-code",
          data: phone,
          method: "POST"
        })
          .then(resp => {
            commit("auth_code_send");
            resolve(resp);
          })
          .catch(err => {
            commit("send_error");
            reject(err.response);
          });
      });
    },
    confirmEmail({ commit }, code) {
      return new Promise((resolve, reject) => {
        commit("send_request");
        Axios({
          url:
            process.env.VUE_APP_API_URL + "/agents/user-profile/confirm-email",
          data: { code: code },
          method: "POST"
        })
          .then(resp => {
            commit("send_success");
            resolve(resp);
          })
          .catch(err => {
            commit("send_error");
            reject(err.response);
          });
      });
    },
    callOrder({ commit }, form_data) {
      return new Promise((resolve, reject) => {
        commit("send_request");
        Axios({
          url: process.env.VUE_APP_API_URL + "/agents/user-profile/call-order",
          data: form_data,
          method: "POST"
        })
          .then(resp => {
            commit("send_success");
            resolve(resp);
          })
          .catch(err => {
            commit("send_error");
            reject(err.response);
          });
      });
    },
    login({ commit }, code) {
      return new Promise((resolve, reject) => {
        commit("send_request");
        Axios({
          url: process.env.VUE_APP_API_URL + "/agents/auth/login",
          data: code,
          method: "POST"
        })
          .then(resp => {
            // const token = resp.data.token;
            const user = resp.data;
            const token = "Bearer " + user.authToken;
            localStorage.setItem("token", token);
            Axios.defaults.headers.common["Authorization"] = token;
            commit("auth_success", { token: token, user: user });
            resolve(resp);
          })
          .catch(err => {
            commit("send_error");
            localStorage.removeItem("token");
            reject(err.response);
          });
      });
    },
    register({ commit }, form) {
      return new Promise((resolve, reject) => {
        commit("send_request");
        Axios({
          url: process.env.VUE_APP_API_URL + "/agents/auth/sign-up",
          data: form,
          method: "POST"
        })
          .then(resp => {
            const user = resp.data;
            const token = "Bearer " + user.authToken;
            localStorage.setItem("token", token);
            Axios.defaults.headers.common["Authorization"] = token;
            commit("auth_success", { token: token, user: user });
            resolve(resp);
          })
          .catch(err => {
            commit("send_error", err);
            localStorage.removeItem("token");
            commit("logout");
            reject(err.response);
          });
      });
    },
    logout({ commit }) {
      return new Promise(resolve => {
        commit("logout");
        localStorage.removeItem("token");
        delete Axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },
    getStepPages({ commit }) {
      return new Promise((resolve, reject) => {
        commit("send_request");
        Axios({
          url: process.env.VUE_APP_API_URL + "/agents/leasing/steps",
          method: "GET"
        })
          .then(resp => {
            commit("setStepPages", resp.data);
            resolve(resp);
          })
          .catch(err => {
            commit("send_error");
            reject(err.response);
          });
      });
    },
    getFaqPages({ commit }) {
      return new Promise((resolve, reject) => {
        commit("send_request");
        Axios({
          url: process.env.VUE_APP_API_URL + "/agents/leasing/faq",
          method: "GET"
        })
          .then(resp => {
            commit("setFaqPages", resp.data);
            resolve(resp);
          })
          .catch(err => {
            commit("send_error");
            reject(err.response);
          });
      });
    },
    getNewCommentsCount({ commit }) {
      return new Promise((resolve, reject) => {
        commit("send_request");
        Axios({
          url: "/agents/leasing/has-new-comments",
          method: "GET"
        })
          .then(resp => {
            commit("setNewComments", resp.data);
            resolve(resp);
          })
          .catch(err => {
            commit("send_error");
            reject(err.response);
          });
      });
    },
    user({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit("send_request");
        Axios.defaults.headers.common["Authorization"] = state.token;
        Axios({
          url: process.env.VUE_APP_API_URL + "/agents/auth/user",
          method: "GET"
        })
          .then(resp => {
            const user = resp.data;
            const token = "Bearer " + user.authToken;
            localStorage.setItem("token", token);
            Axios.defaults.headers.common["Authorization"] = token;
            commit("auth_success", { token: token, user: user });
            resolve(resp);
          })
          .catch(err => {
            commit("send_error");
            reject(err.response);
          });
      });
    },
    saveProfile({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit("send_request");
        Axios.defaults.headers.common["Authorization"] = state.token;
        Axios({
          url: process.env.VUE_APP_API_URL + "/agents/user-profile/update",
          method: "POST",
          data: state.user
        })
          .then(resp => {
            const user = resp.data;
            commit("updateUser", user);
            resolve(resp);
          })
          .catch(err => {
            commit("send_error");
            reject(err.response);
          });
      });
    }
  },
  modules: {}
});
