<template>
  <v-row justify="center">
    <v-col cols="12" md="6">
      <v-expansion-panels accordion v-model="activeItem">
        <v-expansion-panel v-for="(item, i) in items" :key="i">
          <v-expansion-panel-header>{{ item.title }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row justify="center">
              <v-expansion-panels accordion>
                <v-expansion-panel
                  v-for="(childItem, i) in item.child"
                  :key="i"
                >
                  <v-expansion-panel-header>{{
                    childItem.title
                  }}</v-expansion-panel-header>
                  <v-expansion-panel-content class="px-10">
                    {{ childItem.content }}
                    <CallBackButton v-if="childItem.id === 15" />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>
<script>
import CallBackButton from "@/components/CallBackButton";

export default {
  name: "Faq",
  components: {
    CallBackButton
  },
  data: () => ({
    activeItem: [],
    activeChild: null
  }),
  computed: {
    items() {
      return this.$store.state.faqPages;
    },
    isLoad() {
      return this.items.length;
    }
  },
  created() {
    if (this.$route.query.step !== undefined) {
      this.activeItem = this.$route.query.step - 1;
    }
  }
};
</script>
