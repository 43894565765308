<template>
  <v-text-field
    v-model="maskedPhone"
    :counter="11"
    :rules="rules"
    label="Контактный телефон"
    required
    prefix="+7"
    v-mask="'(###) ###-##-##'"
    type="tel"
    :counter-value="v => v.replace(/\D+/g, '').length + 1"
    :error-messages="errors"
    @input="$emit('input', maskedPhone.replace(/\D+/g, ''))"
  ></v-text-field>
</template>

<script>
import { VueMaskFilter } from "v-mask";

export default {
  name: "MaskedPhoneInput",
  model: {
    prop: "phone",
    event: "input"
  },
  props: ["phone", "errors"],
  data: () => ({
    rules: [
      v => !!v || "Введите телефон",
      v =>
        /^\d+$/.test(v.replace(/\D+/g, "")) ||
        "Телефон должен состоять только из цифр",
      v =>
        (v && v.replace(/\D+/g, "").length === 10) ||
        "Телефон должен содержать 11 цифр"
    ],
    maskedPhone: ""
  }),
  watch: {
    phone(val) {
      this.maskedPhone = VueMaskFilter(val, "(###) ###-##-##");
    }
  }
};
</script>

<style scoped></style>
